import { gql } from '@apollo/client'

export const QUERY_DOWNLOAD_CSV = gql`
  query generatePotentialCSV(
    $orgs: [String]
    $state: String
    $edlevels: [String]
    $assessment: String!
    $pools: AWSJSON!
    $downloadId: String!
  ) {
    generatePotentialCSV(
      orgs: $orgs
      state: $state
      edlevels: $edlevels
      assessment: $assessment
      thresholds: $pools
      downloadId: $downloadId
    )
  }
`

export const QUERY_DOWNLOAD_LETTERS = gql`
  query generatePotentialLetters(
    $orgs: [String]
    $edlevels: [String]
    $assessment: String!
    $pools: AWSJSON!
    $downloadId: String!
    $espanol: Boolean
    $signature: String
    $contact: String
  ) {
    generatePotentialLetters(
      orgs: $orgs
      edlevels: $edlevels
      assessment: $assessment
      thresholds: $pools
      downloadId: $downloadId
      espanol: $espanol
      signature: $signature
      contact: $contact
    )
  }
`

export const QUERY_STUDENT_DETAILS = gql`
  query getStudentDetails($catapultId: String!, $assessment: String!, $pools: AWSJSON!) {
    getStudentDetails(catapultId: $catapultId, assessment: $assessment, thresholds: $pools) {
      catapultId
      orgId
      orgName
      districtOrgId
      districtOrgName
      firstName
      lastName
      preferredName
      middleInitial
      studentSchoolId
      aiCode
      ethnicityCd
      birthDate
      genderCd
      evaluatedCutScores
      assessments {
        educationLevelCd
        asmtDate
        asmtEventId
        scaledScores
      }
    }
  }
`

export const QUERY_POTENTIAL_RESULTS = gql`
  query getPotentialResults($orgs: [String]!, $edlevels: [String], $assessment: String!, $pools: AWSJSON!) {
    getPotentialResults(orgs: $orgs, edlevels: $edlevels, assessment: $assessment, thresholds: $pools) {
      catapultId
      firstName
      lastName
      preferredName
      middleInitial
      studentSchoolId
      aiCode
      educationLevelCd
      ethnicityCd
      birthDate
      genderCd
      address {
        streetAddr1
        streetAddr2
        city
        stateCd
        postalCd
        province
        countryCd
      }
      evaluatedCutScores
      scaledScores
    }
  }
`

export const QUERY_AP_COUNTS = gql`
  query getApAssessmentCounts($orgs: [String], $state: String, $apAsmts: [String]!, $educationPeriodCd: Int) {
    getApAssessmentCounts(orgs: $orgs, state: $state, apAsmts: $apAsmts, educationPeriodCd: $educationPeriodCd) {
      apAsmtId
      totalTestTakers
      passingTestTakers
    }
  }
`

export const QUERY_EDLEVEL_COUNTS = gql`
  query getEducationLevelCounts($orgs: [String], $state: String, $assessment: String!) {
    getEducationLevelCounts(orgs: $orgs, state: $state, assessment: $assessment) {
      educationLevelCd
      total
    }
  }
`

export const QUERY_CUTSCORE_COUNTS = gql`
  query getCutScoreCounts($orgs: [String], $state: String, $assessment: String!, $apAsmts: [String]!) {
    getCutScoreCounts(orgs: $orgs, state: $state, assessment: $assessment, apAsmts: $apAsmts) {
      apAsmtId
      cutScoreCounts {
        potentialPercentage
        totals {
          educationLevelCd
          total
        }
      }
    }
  }
`

export const QUERY_ORGS_POTENTIAL = gql`
  query getOrgsWithPotential(
    $orgs: [String]
    $state: String
    $assessment: String!
    $pools: AWSJSON!
    $edlevels: [String]
  ) {
    getOrgsWithPotential(orgs: $orgs, state: $state, assessment: $assessment, thresholds: $pools, edlevels: $edlevels)
  }
`

export const QUERY_TIERS = gql`
  query getScoreTiers {
    getScoreTiers {
      apAsmtId
      description
      asmtScoreTierAbbr
      asmtSampleSize
      asmtCorrelation
    }
  }
`

export const QUERY_CUTSCORES = gql`
  query getCutScores {
    getCutScores {
      apAsmtId
      cutScores {
        potentialPercentage
        minScore
        estimatedPotentialDescr
        estimatedPotentialCd
      }
    }
  }
`

export const QUERY_ASSESSMENTS = gql`
  query getAssessments {
    getAssessments {
      assessment
      eventName
    }
  }
`

export const QUERY_ASSESSMENT_EVENTS = gql`
  query getAssessmentEvents($educationPeriodCd: Int!) {
    getAssessmentEvents(educationPeriodCd: $educationPeriodCd) {
      asmtCd
      asmtCategoryName
      asmtEventId
      asmtId
      adminYear
      adminMonth
      educationPeriodCd
      eventName
      asmtDate
      asmtAdminSeasonCd
      asmtEventTypeCd
      asmtSubtypeCd
      certified
      certDate
      active
      releaseWindows
    }
  }
`
export const QUERY_SETTINGS = gql`
  query getSettings {
    getSettings {
      code
      active
    }
  }
`

export const QUERY_EDPERIODS = gql`
  query getEducationPeriods {
    getEducationPeriods {
      code
      selected
      current
    }
  }
`

// nextDescr @client
// prevDescr @client
// prevCode @client

export const QUERY_EDLEVELS = gql`
  query getEducationLevels {
    getEducationLevels {
      code
      gradeName
    }
  }
`

export const QUERY_AP_ASSESSMENTS = gql`
  query getApAssessments {
    getApAssessments {
      apAsmtId
      asmtName
      asmtAbbr
    }
  }
`

export const QUERY_ETHNICITIES = gql`
  query getEthnicities {
    getEthnicities {
      code
      ethnicityName
    }
  }
`

export const QUERY_GENDERS = gql`
  query getGenders {
    getGenders {
      code
      genderName
    }
  }
`

export const QUERY_STATES = gql`
  query getStates {
    getStates {
      orgId
      regionOrgId
      stateCd
      stateName
    }
  }
`

export const QUERY_REGIONS = gql`
  query getRegions {
    getRegions {
      orgId
      regionName
      regionCd
    }
  }
`

export const QUERY_ORG = gql`
  query getOrg($org: String!) {
    getOrg(org: $org) {
      orgId
      aiCode
      orgName
      parentOrgId
      regionOrgId
      stateOrgId
    }
  }
`

export const QUERY_STATE_ORGS = gql`
  query getStateOrgs($state: String!) {
    getStateOrgs(state: $state) {
      orgId
      aiCode
      orgName
    }
  }
`

export const QUERY_DISTRICT_ORGS = gql`
  query getDistrictOrgs($district: String!) {
    getDistrictOrgs(district: $district) {
      orgId
      orgName
      aiCode
    }
  }
`

export const QUERY_SEARCH_CRITERIA = gql`
  query getSearchCriteria {
    getSearchCriteria @client
  }
`
