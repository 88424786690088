import { Icon } from '@cb/apricot-react'
import classnames from 'classnames'
import { CSVLink } from 'react-csv'

const DownloadCSV = ({
  data = [],
  filename,
  title = 'Download CSV',
  headers,
  className,
  loading,
  disabled,
  onClick,
}) => {
  const classes = classnames(
    'cb-btn cb-btn-primary cb-btn-icon-left',
    className,
    loading && 'cb-btn-pulse',
    disabled && 'cb-disabled'
  )

  return (
    <CSVLink
      id="download-csv-button"
      filename={`${filename}.csv`}
      data={data}
      headers={headers}
      className={classes}
      onClick={onClick}
    >
      <Icon name="download" decorative /> {title}
      <div className="cb-btn-pulse-dots">
        <div className="cb-btn-dots" />
      </div>
    </CSVLink>
  )
}

DownloadCSV.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  filename: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default DownloadCSV
