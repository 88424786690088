import Modal from '../../../_common/modal/Modal'
import DownloadRosterButton from './DownloadRosterButton'
import { canDownload } from '../../../../utils/auth'
import CSVForm from './CSVForm'

const CSVDownload = (props) => {
  const { exams = [], availableOrgs = [], data = [], pools, assessment, disabled } = props
  const isSingleOrgSelected = availableOrgs.length === 1
  const isSingleExamSelected = exams.length === 1
  const directDownload = isSingleOrgSelected && isSingleExamSelected
  const downloadAllowed = canDownload()

  if (directDownload)
    return (
      <DownloadRosterButton
        data={data}
        org={availableOrgs[0]}
        pools={pools}
        assessment={assessment}
        exams={exams}
        disabled={disabled}
      />
    )

  return downloadAllowed && availableOrgs.length ? (
    <Modal
      titleButton="Download Data"
      titleModal={
        <h2>
          <span className="cb-font-weight-light">Download Data</span>
          {isSingleExamSelected ? `: ${exams[0].label}` : ''}
        </h2>
      }
      controlledCloseOnly
      body={(modalProps) => <CSVForm {...modalProps} {...props} />}
      options={{
        className: 'cb-modal-custom-footer',
        disableHeightAdjustment: true,
        analytics: true,
        analyticsTitle: 'Download Data',
      }}
      disabled={disabled}
    />
  ) : null
}

CSVDownload.propTypes = {
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  state: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  data: PropTypes.arrayOf(PropTypes.object),
  pools: PropTypes.object,
  assessment: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
}

export default CSVDownload
