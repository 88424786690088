import CBInputMethod from '@cb/apricot/CBInputMethod'
import get from 'lodash/get'
import { useEffect } from 'react'
import { PAGE_CODES, trackPage } from '../../utils/analytics'
import { canViewApp, useAuth } from '../../utils/auth'
import { APP_CONFIG, ERROR, HIDE_EVENT, SHOW_EVENT } from '../../utils/constants'
import Loader from '../_common/loader/Loader'
import Routes from './Routes'

const App = () => {
  const auth = useAuth()
  const [show, setShow] = useState(false)
  const noAccess = auth.loggedIn && !canViewApp()
  const error = auth.error ? ERROR.AUTH : noAccess ? ERROR.ACCESS_DENIED : undefined

  useEffect(() => {
    const showApp = () => setShow(true)
    const hideApp = () => setShow(false)
    document.addEventListener(SHOW_EVENT, showApp)
    document.addEventListener(HIDE_EVENT, hideApp)

    CBInputMethod() // adds extra accessibility elements when using keyboard

    return () => {
      document.removeEventListener(SHOW_EVENT, showApp)
      document.removeEventListener(HIDE_EVENT, hideApp)
    }
  }, [])

  useEffect(() => {
    const { toggle } = get(window, APP_CONFIG)
    toggle(show)
  }, [show])

  useEffect(() => {
    setShow(!auth.loggedOut)
    if (auth.loggedOut && window.location.pathname !== '/') window.location = window.location.origin
  }, [auth.loggedOut])

  useEffect(() => {
    if (noAccess) trackPage(null, PAGE_CODES.forbidden)
  }, [noAccess])

  useEffect(() => {
    if (auth.error) trackPage(null, PAGE_CODES.catapult)
  }, [auth.error])

  return (
    <Loader loading={auth.loading} spinnerCenter error={error} errorClass="cb-margin-top-72" loaded={auth.loggedIn}>
      {auth.loggedIn && !error ? <Routes /> : null}
    </Loader>
  )
}

export default App
