import classNames from 'classnames'

const Header = ({ title, className = '', id = '' }) => {
  const classes = classNames('cb-h3 cb-roboto-light cb-margin-bottom-8', className)
  return (
    <h1 className={classes} id={id}>
      {title}
    </h1>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default Header
