// local storage functions
export const setStorageItem = (key, value) => localStorage?.setItem(key, value)
export const setStorageJSON = (key, value) => setStorageItem(key, JSON.stringify(value))
export const getStorageItem = (key) => localStorage?.getItem(key)
export const getStorageJSON = (key) => JSON.parse(getStorageItem(key)) || {}
export const removeStorageItem = (key) => localStorage?.removeItem(key)
export const clearStorage = () => localStorage?.clear()

// session storage functions
export const setSessionItem = (key, value) => sessionStorage?.setItem(key, value)
export const setSessionJSON = (key, value) => setSessionItem(key, JSON.stringify(value))
export const getSessionItem = (key) => sessionStorage?.getItem(key)
export const getSessionJSON = (key) => JSON.parse(getSessionItem(key)) || {}
export const removeSessionItem = (key) => sessionStorage?.removeItem(key)
export const clearSession = () => sessionStorage?.clear()
