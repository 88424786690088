import { Select } from '@cb/apricot-react-forms'
import { Pager } from '@cb/apricot-react-pager'
import { useEffect } from 'react'

const PAGER_OPTIONS = [25, 50, 100]
const OPTIONS = PAGER_OPTIONS.map((p) => ({ label: p, value: p }))

const Controls = ({ onChange, total, topPosition = 0, label }) => {
  const [perPage, setPerPage] = useState(PAGER_OPTIONS[0])
  const [page, setPage] = useState(1)
  const maxPages = Math.ceil(total / perPage)
  const start = (page - 1) * perPage
  const end = start + perPage > total ? total : start + perPage

  useEffect(() => {
    onChange(start, end)
    window.scrollTo(0, topPosition)
  }, [start, end, onChange, topPosition])

  return (
    <div className="row justify-content-between cb-margin-top-16 align-items-center">
      <div className="col-md-4">
        <Select
          id="students-per-page-dropdown"
          values={OPTIONS}
          floating
          label="Students per Page"
          name="perPageSelect"
          onChange={(value) => {
            setPage(1)
            setPerPage(parseInt(value, 10))
          }}
        />
      </div>
      <div id="pager-table-stats" className="col-md-4 cb-align-md-up-center cb-margin-sm-top-16 cb-font-weight-medium">
        {maxPages > 1 ? `Viewing ${start + 1}-${end} of` : ''} {total} {label}
        {total > 1 ? 's' : ''}
      </div>
      <div className="col-md-4 cb-align-md-up-right cb-margin-sm-top-16">
        <Pager
          pagerId="roster-pagination-table"
          buttonId="roster-pagination-button"
          max={maxPages}
          current={page}
          ariaLabel="Student roster pagination table"
          onPageChange={(page) => setPage(page)}
        />
      </div>
    </div>
  )
}

Controls.propTypes = {
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  topPosition: PropTypes.number,
  label: PropTypes.string.isRequired,
}

export default Controls
