const Disclaimers = () => (
  <div id="footnotes" role="region" aria-label="Footnotes">
    <ol className="cb-font-size-xsmall cb-margin-top-48 cb-text-list-no-indent">
      <li id="footnote1" className="cb-margin-bottom-24">
        <div className="cb-margin-bottom-8">
          Selecting 'All Grades' generates data for all students who took the selected exam and marked any of the
          following available responses on their answer sheets at the time they took the exam (as applicable): not yet
          in 6th grade, 6th grade, 7th grade, not yet in 8th grade, 8th grade, 9th grade, 10th grade, greater than 10th
          grade, 11th grade, 12th grade, other, and no response.
        </div>
        <div className="cb-margin-bottom-8">
          Keep in mind that while students with State-Approved Accommodations will appear in AP Potential, AP Potential
          was developed using data for students with College Board approved accommodations.
        </div>
        <div>
          AP Potential for AP Art History, AP Computer Science Principles, AP European History, AP Human Geography, AP
          Seminar, and AP World History: Modern is fueled by correlations and expectancy tables that are based on the
          scores of 9th, 10th, and 11th grade PSAT/NMSQT and SAT test takers. For all other AP subjects, AP Potential is
          fueled by correlations and expectancy tables that are based on the scores of 10th and 11th grade PSAT/NMSQT
          and SAT test takers.
        </div>
      </li>
      <li id="footnote2">
        <div className="cb-margin-bottom-8">
          Although AP Potential is no longer reported for Calculus BC, students with AP Potential for Calculus AB who
          perform well in courses leading to Calculus may consider taking Calculus BC.
        </div>
        <div className="cb-margin-bottom-8">
          Similarly, although AP Potential is not reported for Physics 2, students with AP Potential for Physics 1 who
          perform well in recommended prerequisite courses for Physics 2 may consider taking this course. When making
          final course placement decisions, it is important to consider factors such as prior coursework, student
          interest, and motivation.{' '}
        </div>
        <div>
          AP Potential is not yet available for AP Research, as more data are required for valid results. However, we
          recommend that educators use the prerequisites listed in the Course and Exam Descriptions (where applicable)
          as a guide to encourage students to enroll in these new courses.
        </div>
      </li>
    </ol>
  </div>
)

export default Disclaimers
