import { RadioButton, RadioButtonGroup } from '@cb/apricot-react-forms'
import { QUERY_EDPERIODS } from '../../../services/graphql/queries'
import { useCustomQuery } from '../../../services/graphql/utils'
import { ERROR } from '../../../utils/constants'
import { getEducationPeriodDescr } from '../../../utils/date'
import Loader from '../../_common/loader/Loader'

const EdPeriods = ({ selected = '', onChange }) => {
  const { loading, error, data } = useCustomQuery(QUERY_EDPERIODS)
  const formattedEdPeriods = data?.map((d) => ({
    value: String(d.code),
    label: `${getEducationPeriodDescr(d.code)} ${d.current ? '(current)' : ''}`,
  }))

  return (
    <Loader loaded={Boolean(data)} loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined}>
      <fieldset className="cb-margin-bottom-24" aria-labelledby="edperiodsGroup">
        <legend id="edperiodsGroup" className="cb-h5 cb-margin-bottom-8">
          Education Periods
        </legend>
        <p className="cb-font-size-small cb-margin-bottom-16">Select the "current" education period.</p>
        <RadioButtonGroup name="edPeriodRadios" defaultValue={selected} vertical onChange={(value) => onChange(value)}>
          {formattedEdPeriods?.map((s) => (
            <RadioButton key={s.value} {...s} />
          ))}
        </RadioButtonGroup>
      </fieldset>
    </Loader>
  )
}

EdPeriods.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
export default EdPeriods
