import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { trackError } from '../../utils/analytics'
import { objectify } from '../../utils/misc'

export const useTrackError = (name, error) => {
  useEffect(() => {
    if (error) {
      trackError(`${name} failed`)
      console.error(error)
    }
  }, [name, error])
}

export const formatAppsyncErrors = (error) => {
  const { message } = error || {}
  const formattedMsg = message ? message.replace('GraphQL error:', '').trim() : null
  return !error ? null : formattedMsg || 'Please try again.'
}

export const formatResults = (data, transform) => {
  const result = (data && Object.values(data)[0]) || undefined
  return transform && result ? transform(result) : result
}

export const getQueryName = (query) => query?.definitions[0]?.name?.value

export const transformResultsToObject = (data, objAttrs) => {
  const [key, value] = objAttrs
  return objectify(data, ({ [key]: _k, ...d }) => ({ [_k]: value ? d[value] : d }), objAttrs)
}

export const useCustomQuery = (query, options, transform) => {
  const { loading, error, data } = useQuery(query, options)
  const name = getQueryName(query)
  const err = formatAppsyncErrors(error)

  useTrackError(name, err)

  return {
    loading,
    error: err,
    data: formatResults(data, transform),
  }
}

export const useCustomLazyQuery = (query, options, transform) => {
  const [fetch, { loading, data, error, called }] = useLazyQuery(query, options)
  const name = getQueryName(query)
  const err = formatAppsyncErrors(error)

  useTrackError(name, err)

  return {
    fetch,
    loading,
    called,
    error: err,
    data: formatResults(data, transform),
  }
}

export const useLookupQuery = (query, objAttrs, options) =>
  useCustomQuery(query, options, (data) => transformResultsToObject(data, objAttrs))

export const useLookupLazyQuery = (query, objAttrs, options) =>
  useCustomLazyQuery(query, options, (data) => transformResultsToObject(data, objAttrs))
