import { Icon } from '@cb/apricot-react'
import { Select } from '@cb/apricot-react-forms'
import { Column, Table } from '@cb/apricot-react-table'
import orderBy from 'lodash/orderBy'
import { useEffect, useRef } from 'react'
import { QUERY_AP_COUNTS, QUERY_CUTSCORE_COUNTS, QUERY_SETTINGS } from '../../../../services/graphql/queries'
import { useCustomQuery, useLookupLazyQuery, useLookupQuery } from '../../../../services/graphql/utils'
import { getExamOrgVariables, updateCriteria } from '../../../../services/session/findstudents'
import { DEFAULT_POOL, ERROR, POOLS } from '../../../../utils/constants'
import { getEducationPeriodDescr } from '../../../../utils/date'
import { normalizeString } from '../../../../utils/string'
import Header from '../../../_common/header/Header'
import Loader from '../../../_common/loader/Loader'

import './studentpool.scss'

const POTENTIAL_TEST_TAKER_FIELD = 'potentialTestTakersConditional'
const SUBJECT_FIELD = 'subject'

const getSortField = (field) => {
  switch (field) {
    case POTENTIAL_TEST_TAKER_FIELD:
      return 'potentialTestTakers'
    case SUBJECT_FIELD:
      return 'label'
    default:
      return field
  }
}
const studentPoolClass = 'cb-padding-8 cb-align-right'

const StudentPool = ({ criteria }) => {
  const orgLength = useRef(getExamOrgVariables(true).orgs?.length)
  const { exams = [], edlevels = {}, pools = {} } = criteria
  const edPeriod = useCustomQuery(QUERY_SETTINGS, {}, (data) => {
    const { active = [] } = data.find((d) => d.code === 'edPeriod') || {}
    return Number(active[0] - 1)
  })
  const { fetch: fetchCounts, ...apCounts } = useLookupLazyQuery(QUERY_AP_COUNTS, ['apAsmtId'])
  const cutscoreCounts = useLookupQuery(QUERY_CUTSCORE_COUNTS, ['apAsmtId', 'cutScoreCounts'], {
    variables: getExamOrgVariables(true),
  })
  const tableData = orderBy(
    exams.map((s) => {
      const { totalTestTakers, passingTestTakers } = (apCounts.data && apCounts.data[s.value]) || {}
      const poolsByExam = (cutscoreCounts.data && cutscoreCounts.data[s.value]) || []
      const availablePools = poolsByExam.map((p) => p.potentialPercentage).sort()

      const selectedPool = Number(pools[s.value])
      // some cutscores percentages will not have counts associated with them
      // in that event, the next available percentage will be used
      // the cutscores are ordered least to greatest, so it will be the next higer result.
      // https://confluence.collegeboard.org/display/APP1/1.2+Cut+Scores
      const { totals = [] } =
        poolsByExam.find((p) => p.potentialPercentage === selectedPool) ||
        poolsByExam.find((p) => p.potentialPercentage === availablePools.find((p) => p > selectedPool)) ||
        {}
      const potentialTestTakers = totals.reduce(
        (sum, t) =>
          edlevels.all || edlevels.selections.find((s) => s.value === t.educationLevelCd) ? sum + t.total : sum,
        0
      )
      return {
        ...s,
        subject:
          orgLength.current === 1 ? (
            <div>
              {s.label} {totalTestTakers === 0 ? '' : <Icon name=" cb-check" />}
            </div>
          ) : (
            s.label
          ),
        pool: selectedPool,
        totalTestTakers,
        passingTestTakers,
        potentialTestTakers,
      }
    }),
    'label',
    'asc'
  )

  const getConditionalFormattingForCourseOffering = (_value, { totalTestTakers }, _rowIndex) =>
    `${studentPoolClass} ${totalTestTakers === 0 ? 'cb-blue4-bg' : 'cb-green2-bg'}`

  const updatePools = (updates) => updateCriteria({ pools: updates })

  useEffect(() => {
    updatePools(exams.reduce((obj, s) => ({ ...obj, [s.value]: pools[s.value] || DEFAULT_POOL }), {}))
  }, [pools, exams])

  useEffect(() => {
    if (edPeriod.data) fetchCounts({ variables: { ...getExamOrgVariables(true), educationPeriodCd: edPeriod.data } })
  }, [fetchCounts, edPeriod.data])

  const loading = apCounts.loading || cutscoreCounts.loading || edPeriod.loading
  const error = apCounts.error || cutscoreCounts.error || edPeriod.error
  const loaded = Boolean(apCounts.data && cutscoreCounts.data && edPeriod.data)

  return (
    <Loader loaded={loaded} loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined}>
      <Header id="header-title" title="Student Pools" />
      <p id="header-text" className="cb-font-size-small cb-margin-bottom-24">
        Adjust the percentages below to increase or decrease the number of students included on the rosters you want to
        generate. You may select a different percentage for each AP subject.{' '}
        <a href="https://appotential.collegeboard.org/expectancy-tables">Learn about student pools</a>
      </p>
      <Table
        data={tableData}
        stickyHeader
        striped
        responsive
        className="appotential-student-pool-table"
        tableId="appotential-student-pool-table"
        sortFn={(data, sort) =>
          sort.field ? orderBy(data, getSortField(sort.field), sort.ascending ? 'asc' : 'desc') : data
        }
      >
        <Column
          field="pool"
          title="Threshold %"
          component={({ value, row }) => (
            <Select
              id={normalizeString(`pool-dropdown-${row.label}`)}
              truncate={false}
              condensed
              ariaLabel={`Select Threshold for ${row.label}`}
              values={POOLS.map((p) => ({ value: p, label: p }))}
              name={`pool${row.value}`}
              value={value}
              onChange={(value) => updatePools({ ...pools, [row.value]: value })}
            />
          )}
        />
        <Column title="Subject" field="subject" sortable />
        <Column
          title={`${getEducationPeriodDescr(edPeriod.data + 1) || ''} Students with AP Potential`}
          field="potentialTestTakers"
          sortable
          classNameFunc={getConditionalFormattingForCourseOffering}
          highlight={() => orgLength.current !== 1}
        />
        <Column
          title={`${getEducationPeriodDescr(edPeriod.data) || ''} AP Students|Total Exams Taken`}
          field="totalTestTakers"
          sortable
          className="cb-align-right"
        />
        <Column
          title={`${getEducationPeriodDescr(edPeriod.data)} AP Students|Exams Scored 3 or Higher`}
          field="passingTestTakers"
          sortable
          className="cb-align-right"
        />
      </Table>
      <div className="cb-font-size-small cb-margin-top-24 cb-margin-bottom-24">
        <ul>
          {orgLength.current === 1 && (
            <>
              <li className="cb-green1-color">
                <b>
                  <Icon name=" cb-check" /> - indicates the subject is offered at the school.
                </b>
              </li>
              <li className="cb-blue2-color">
                <b>
                  No check mark indicates the subject is <u>not</u> offered at the school.
                </b>
              </li>
            </>
          )}
          <li>
            Threshold % is the likelihood that a student will earn a qualifying score of 3 or higher on the AP Exam for
            the subject. The default setting is 60%; setting a higher threshold will result in fewer students being
            identified with AP Potential, and setting a lower threshold will result in more students being identified.
          </li>
        </ul>
      </div>
    </Loader>
  )
}

StudentPool.propTypes = {
  criteria: PropTypes.object.isRequired,
}

export default StudentPool
