import { Select } from '@cb/apricot-react-forms'
import { trackSubjectSelection } from '../../../utils/analytics'
import { DEFAULT_ALL } from '../../../utils/constants'
import Autocomplete from '../../_common/forms/autocomplete/Autocomplete'

const Filters = ({
  selected,
  update,
  exams,
  availableOrgs = [],
  disableExamOption,
  disableOrgOption,
  defaultValue,
}) => {
  const orgCount = availableOrgs.length
  const isMultiOrg = orgCount > 1

  return (
    <div className="row align-items-center">
      {isMultiOrg ? (
        <div className="col-sm-7 cb-margin-xs-bottom-16">
          {orgCount < 30 ? (
            <Select
              id="orgFilterSelect"
              label="School"
              name="orgFilterSelect"
              labelNoValue="Select"
              key={`${orgCount}key`}
              floating
              values={availableOrgs}
              value={selected.org}
              onChange={(value) => update({ org: value })}
              disabled={disableOrgOption}
            />
          ) : (
            <Autocomplete
              isValid
              label={'School'}
              labelClassName={'cb-focus-fl cb-value-fl'}
              fieldName="school"
              defaultValue={defaultValue}
              fetch={(term) => availableOrgs.filter((o) => o.label.toLowerCase().includes(term.toLowerCase()))}
              disabled={disableOrgOption}
              onSelection={(org) => update({ org: org.value })}
              onBuild={(item) => ({
                label: item.label,
                value: item.value,
                children: item.label,
              })}
            />
          )}
        </div>
      ) : null}
      <div className={isMultiOrg ? 'col-sm-5' : 'col-sm-7'}>
        <Select
          id="roster-examFilter"
          key={`examFilter-reset-key-${disableExamOption}`}
          label="Subject"
          name="examFilter"
          floating
          values={[{ label: 'All Subjects', value: DEFAULT_ALL }, ...exams]}
          value={selected.exam}
          disabled={disableExamOption}
          onChange={(value) => {
            update({ exam: value })
            trackSubjectSelection(value)
          }}
        />
      </div>
    </div>
  )
}

Filters.propTypes = {
  selected: PropTypes.shape({
    org: PropTypes.string,
    exam: PropTypes.string.isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  disableExamOption: PropTypes.bool,
  disableOrgOption: PropTypes.bool,
  defaultValue: PropTypes.string,
}

export default Filters
