import { ErrorNotification } from '@cb/apricot-react-notification'
import classnames from 'classnames'

const Error = ({ message, title, className, container = true }) => {
  const classes = classnames(title && container && 'container', !title && 'cb-red1-color', className)

  if (title)
    return (
      <div className={classes}>
        <ErrorNotification title={title}>{message}</ErrorNotification>
      </div>
    )

  if (message) return <p className={classes}>{message}</p>

  return null
}

Error.propTypes = {
  container: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  className: PropTypes.string,
}

export default Error
