import Modal from '../../../_common/modal/Modal'
import { canDownload } from '../../../../utils/auth'
import LettersForm from './LettersForm'

const GenerateLetter = (props) => {
  const { state, disabled } = props
  const downloadAllowed = canDownload() && !state

  return downloadAllowed ? (
    <Modal
      disabled={disabled}
      titleButton="Generate Sample Letters"
      titleModal="Generate Sample Letters"
      triggerButtonType="naked"
      controlledCloseOnly
      body={(modalProps) => <LettersForm {...modalProps} {...props} />}
      options={{ className: 'cb-modal-custom-footer', disableHeightAdjustment: true, analytics: true }}
    />
  ) : null
}

GenerateLetter.propTypes = {
  disabled: PropTypes.bool,
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  state: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
}

export default GenerateLetter
