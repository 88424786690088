export const SHOW_EVENT = 'show.appotential'
export const HIDE_EVENT = 'hide.appotential'
export const APP_CONFIG = 'appotential.app'

export const DEFAULT_ALL = 'ALL'

export const POOLS = ['10', '20', '30', '40', '50', '60', '70', '80', '90']
export const DEFAULT_POOL = '60'

export const SCORE_TIERS_TOT = 'TOT'
export const SCORE_TIERS_EBRW = 'EBRW'
export const SCORE_TIERS_R = 'R'
export const SCORE_TIERS_W = 'W'
export const SCORE_TIERS_M = 'MSS'

// AP-45284: standard error message for all errors.
export const ERROR = {
  DEFAULT: {
    title: `Error`,
    message: `Error loading data. Please try again at a later time.`,
  },
  ADMIN_SAVE_ASSESSMENTS: {
    message: `Error: Unable to save assessments.`,
  },
  ADMIN_LOAD_SETTINGS: {
    title: 'Error',
    message: `Unable to Load Settings.`,
  },
  ADMIN_SAVE_SETTINGS: {
    message: `Error: Unable to save settings`,
  },
  AUTH: {
    title: 'AP Potential Could Not Load',
    message: 'An error occurred while initializing AP Potential. Please refresh the page to try again.',
  },
  ACCESS_DENIED: {
    title: 'Access Denied',
    message:
      'You do not have access to use AP Potential. If you think this is incorrect or would like access, please contact Customer Service.',
  },
}
