import { Button } from '@cb/apricot-react-button'
import { Modal as CBModal } from '@cb/apricot-react-modal'
import kebabCase from 'lodash/kebabCase'

// this modal is an extension of apricot's
// the main difference is that it only renders the modal's content when open
const Modal = ({
  titleModal,
  titleButton,
  triggerButtonType = 'primary',
  disabled,
  footer: Footer,
  body: Body,
  options,
  controlledCloseOnly,
}) => {
  const modalId = kebabCase(titleButton)
  const [open, setOpen] = useState(false)
  const [showModalContents, setShowModalContents] = useState(false)
  const controlledCloseProps = controlledCloseOnly
    ? { clickOverlayToClose: false, escClose: false, showHeaderCloseGlyph: false }
    : {}

  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)

  return (
    <>
      <Button id={`${modalId}-modal-button`} variant={triggerButtonType} onClick={openModal} disabled={disabled}>
        {titleButton}
      </Button>
      <CBModal
        modalId={modalId}
        headerId={`${modalId}-header`}
        focusId={`${modalId}-focus`}
        closeButtonId={`${modalId}-close`}
        open={open}
        onClose={() => {
          if (open) closeModal()
          setTimeout(() => setShowModalContents(false), 500)
        }}
        onShow={() => setShowModalContents(true)}
        title={titleModal}
        withFooter={Boolean(Footer)}
        footer={Footer ? <Footer closeModal={closeModal} openModal={openModal} /> : undefined}
        {...options}
        {...controlledCloseProps}
        escClose={false}
      >
        {showModalContents ? <Body closeModal={closeModal} /> : null}
      </CBModal>
    </>
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  titleModal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleButton: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footer: PropTypes.func,
  body: PropTypes.func.isRequired,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  controlledCloseOnly: PropTypes.bool,
  triggerButtonType: PropTypes.string,
}

export default Modal
