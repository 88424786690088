import { Icon } from '@cb/apricot-react'
import { useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import {
  QUERY_ORGS_POTENTIAL,
  QUERY_POTENTIAL_RESULTS,
  QUERY_SEARCH_CRITERIA,
  QUERY_STATE_ORGS,
} from '../../services/graphql/queries'
import { useCustomLazyQuery, useCustomQuery } from '../../services/graphql/utils'
import { getSearchVariables, isValid, resetCriteria } from '../../services/session/findstudents'
import { DEFAULT_ALL, ERROR } from '../../utils/constants'
import { APP_LINKS } from '../../utils/navigation'
import Header from '../_common/header/Header'
import Loader from '../_common/loader/Loader'
import CSVDownload from './downloads/csv/CSVDownload'
import GenerateLetter from './downloads/letters/LettersDownload'
import Filters from './filters/Filters'
import SnapshotTable from './tables/SnapshotTable'
import SubjectTable from './tables/SubjectTable'

const MSG_NO_STUDENTS = 'No students available.'

const StudentRoster = () => {
  const { fetch: fetchStates, ...stateOrgs } = useCustomLazyQuery(QUERY_STATE_ORGS)
  const { fetch: fetchOrgsWithPotential, ...orgPotential } = useCustomLazyQuery(QUERY_ORGS_POTENTIAL)
  const { fetch: fetchPotential, ...results } = useCustomLazyQuery(QUERY_POTENTIAL_RESULTS)
  const criteria = useCustomQuery(QUERY_SEARCH_CRITERIA)
  const [availableOrgs, setAvailableOrgs] = useState(undefined)
  const { exams = [], org, assessment, pools } = criteria.data || {}
  const orgCount = availableOrgs?.length
  const singleOrg = orgCount === 1 && availableOrgs[0]
  const [filters, setFilters] = useState({ org: undefined, exam: DEFAULT_ALL })
  const [defaultValue, setDefaultValue] = useState('')
  const redirect = criteria.data && !isValid()
  const visibleData = results.data
    ? filters.exam === DEFAULT_ALL
      ? results.data
      : results.data.filter((d) => d.evaluatedCutScores[filters.exam])
    : []

  const updateFilters = (updates) => setFilters((f) => ({ ...f, ...updates }))
  const message = (msg) => <p className="cb-margin-top-48">{msg || MSG_NO_STUDENTS}</p>

  useEffect(() => {
    if (org?.state) fetchStates({ variables: { state: org.state?.value } })
  }, [org, fetchStates])

  useEffect(() => {
    if (availableOrgs?.length && !filters.org) {
      // when availableOrgs is not empty and filters.org is undefined, choose the first one
      setFilters((filters) => ({ ...filters, org: availableOrgs[0].value }))
      setDefaultValue(availableOrgs[0].label)
    } else if (availableOrgs?.length && filters.org) {
      // when availableOrgs is not empty and filters.org is defined
      const selectedOrgName = availableOrgs?.reduce((acc, org) => {
        if (org.value === filters.org) {
          return org.label
        }
        return acc
      }, '')
      setDefaultValue(selectedOrgName ?? defaultValue)
    }
  }, [availableOrgs, filters, defaultValue])

  useEffect(() => {
    if (filters.org) fetchPotential({ variables: getSearchVariables([filters.org]) })
  }, [filters.org, fetchPotential])

  useEffect(() => {
    if (singleOrg) updateFilters({ org: singleOrg.value })
  }, [singleOrg])

  useEffect(() => {
    if (org) {
      if (org.selections?.length !== 1) fetchOrgsWithPotential({ variables: getSearchVariables() })
      else setAvailableOrgs(org.selections)
    }
  }, [org, fetchOrgsWithPotential])

  useEffect(() => {
    if (orgPotential.data) {
      const selectedOrgs = org.state
        ? stateOrgs.data?.map((d) => ({ label: `${d.orgName} (${d.aiCode})`, value: d.orgId }))
        : org.selections
      setAvailableOrgs(selectedOrgs?.filter((s) => orgPotential.data.includes(s.value)))
    }
  }, [orgPotential.data, stateOrgs.data, org])

  if (redirect) return <Navigate to={'/'} replace />

  if (criteria.data) {
    return (
      <div className="container">
        <Loader
          loaded={Boolean(availableOrgs)}
          loading={stateOrgs.loading || orgPotential.loading}
          spinnerCenter
          error={stateOrgs.error || orgPotential.error ? ERROR.DEFAULT : undefined}
        >
          <div className="cb-font-size-small">
            <Link id="back-link" to={APP_LINKS.studentpool}>
              <Icon name="west" decorative size="12" /> Back
            </Link>
            <Link
              id="start-new-search-link"
              to="/"
              className="cb-margin-left-16 cb-padding-left-16 cb-border-left"
              onClick={resetCriteria}
            >
              Start new search
            </Link>
          </div>

          <div className="display-flex cb-margin-top-32">
            <Header id="header-title" title={`Student Roster${singleOrg ? ':' : ''}`} />
            {singleOrg ? (
              <h2 id="header-title-detail" className="cb-h3 cb-font-weight-medium cb-margin-left-4">
                {singleOrg.label}
              </h2>
            ) : null}
          </div>
          <p id="header-text" className="cb-font-size-small">
            {singleOrg
              ? `The following roster includes students identified based on your selections. A checkmark indicates the student is in the pool you defined for that subject. Click on the student's name to view student details, including gender, ethnicity, and a view of which pool the student falls into for each subject you have selected.`
              : `The following roster includes students identified based on your selections. The first school in your district with available results has been listed by default, but you can change the school in the \'School\' area below. You can download data for all schools with available results by using the \'Download Data\' button. A checkmark indicates the student is in the pool you defined for that subject. Click on the student\'s name to view student details, including gender, ethnicity, and a view of which pool the student falls into for each subject you have selected.`}
          </p>
          <div className="row justify-content-between cb-margin-top-48 cb-margin-bottom-16">
            <div className="col-sm-5 col-md-6 col-lg-7">
              <Filters
                exams={exams}
                availableOrgs={availableOrgs}
                selected={filters}
                update={updateFilters}
                disableOrgOption={!criteria.data || results.loading || !orgCount}
                disableExamOption={!criteria.data || results.loading || !orgCount || results.data?.length === 0}
                defaultValue={defaultValue}
              />
            </div>
            <div className="col-sm-7 col-md-6 col-lg-5 display-flex justify-content-sm-end cb-margin-xs-top-16 justify-content-xs-center">
              <GenerateLetter
                exams={exams}
                availableOrgs={availableOrgs}
                state={org.state}
                disabled={results.loading}
              />
              <div className="cb-margin-sm-down-right-8 cb-margin-md-up-left-8">
                <CSVDownload
                  exams={exams}
                  availableOrgs={availableOrgs}
                  state={org.state}
                  pools={pools}
                  data={results.data}
                  assessment={assessment}
                  disabled={results.loading}
                />
              </div>
            </div>
          </div>
          {availableOrgs?.length === 0
            ? message()
            : !filters.org && !singleOrg
              ? message(`Select an organization to view your students' potential or download the results.`)
              : null}
          <Loader
            loaded={Boolean(results.data)}
            loading={results.loading}
            spinnerCenter
            error={results.error ? ERROR.DEFAULT : undefined}
          >
            {visibleData.length ? (
              filters.exam === DEFAULT_ALL ? (
                <SnapshotTable data={visibleData} exams={exams} pools={pools} assessment={assessment} />
              ) : (
                <SubjectTable data={visibleData} pools={pools} assessment={assessment} subject={filters.exam} />
              )
            ) : (
              message()
            )}
          </Loader>
        </Loader>
      </div>
    )
  }

  return null
}

export default StudentRoster
