import { Icon } from '@cb/apricot-react'
import { Column, Table } from '@cb/apricot-react-table'
import { useEffect } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import {
  QUERY_ASSESSMENTS,
  QUERY_EDLEVELS,
  QUERY_ETHNICITIES,
  QUERY_GENDERS,
  QUERY_SEARCH_CRITERIA,
  QUERY_STUDENT_DETAILS,
} from '../../services/graphql/queries'
import { useCustomLazyQuery, useCustomQuery, useLookupQuery } from '../../services/graphql/utils'
import { getStudentVariables, isValid } from '../../services/session/findstudents'
import { canViewDistrictData } from '../../utils/auth'
import { ERROR, SCORE_TIERS_EBRW, SCORE_TIERS_M, SCORE_TIERS_R, SCORE_TIERS_TOT } from '../../utils/constants'
import { FORMATS, formatDate } from '../../utils/date'
import { APP_LINKS } from '../../utils/navigation'
import Header from '../_common/header/Header'
import Loader from '../_common/loader/Loader'
import DetailsSection from './DetailsSection'

const getEBRWLabel = (scaledScores) =>
  // only pnp has reading score
  scaledScores[SCORE_TIERS_R] ? `ERW Score` : `RW Score`

const StudentDetail = () => {
  const criteria = useCustomQuery(QUERY_SEARCH_CRITERIA)
  const assessments = useLookupQuery(QUERY_ASSESSMENTS, ['assessment', 'eventName'])
  const genders = useLookupQuery(QUERY_GENDERS, ['code', 'genderName'])
  const ethnicities = useLookupQuery(QUERY_ETHNICITIES, ['code', 'ethnicityName'])
  const grades = useLookupQuery(QUERY_EDLEVELS, ['code', 'gradeName'])
  const { catapultId } = useParams()
  const { assessment, pools, exams = [] } = criteria.data || {}
  const { fetch, data = {}, loading, error } = useCustomLazyQuery(QUERY_STUDENT_DETAILS)
  const subjectData = exams.map((s) => ({
    name: s.label,
    pool: pools[s.value],
    potentialPercentage: data.evaluatedCutScores ? data.evaluatedCutScores[s.value] : 0,
  }))
  const redirect = criteria.data && !isValid()

  useEffect(() => {
    if (criteria.data && isValid) fetch({ variables: getStudentVariables(catapultId) })
  }, [criteria.data, fetch, catapultId])

  if (redirect) return <Navigate to={'/'} replace />

  return (
    <Loader loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined} loaded={Boolean(data)}>
      <div className="container cb-margin-top-32">
        <Link id="back-to-student-roster-link" to={APP_LINKS.roster} className="cb-font-size-small">
          <Icon name="west" decorative /> Back to Student Roster
        </Link>
        <Header
          id="header-title"
          title={`${data.firstName} ${data.lastName} ${
            data.preferredName ? `(Preferred First Name: ${data.preferredName})` : ''
          }`}
          className="cb-margin-top-32 cb-margin-bottom-24"
        />
        <DetailsSection
          details={[
            { heading: 'Student ID', content: data.studentSchoolId || 'Not provided' },
            { heading: 'Date of Birth', content: formatDate(data.birthDate) },
            { heading: 'Gender', content: genders.data && genders.data[data.genderCd] },
            { heading: 'Ethnicity', content: ethnicities.data && ethnicities.data[data.ethnicityCd] },
            { heading: 'High School', content: `${data.orgName} (${data.aiCode})` },
            canViewDistrictData()
              ? { heading: 'District', content: `${data.districtOrgName} (${data.districtOrgId})` }
              : undefined,
          ]}
        />
        <h2 id="test-admin-title" className="cb-margin-bottom-24 cb-h4">
          {assessments.data && assessments.data[assessment?.value]}
        </h2>
        {data.assessments?.map((r) => (
          <DetailsSection
            key={r.asmtEventId}
            details={[
              { heading: 'Grade', content: grades.data && grades.data[r.educationLevelCd] },
              { heading: 'Month', content: formatDate(r.asmtDate, FORMATS.month) },
              { heading: 'Total Score', content: r.scaledScores[SCORE_TIERS_TOT] },
              { heading: getEBRWLabel(r.scaledScores), content: r.scaledScores[SCORE_TIERS_EBRW] },
              { heading: 'Math Score', content: r.scaledScores[SCORE_TIERS_M] },
            ]}
          />
        ))}
        <h2 id="potential-result-title" className="cb-margin-bottom-24 cb-h4">
          Potential Results
        </h2>
        <Table
          tableId="student-detail-table"
          responsive
          stickyHeader
          data={subjectData}
          rowHighlighted={({ pool, potentialPercentage }) => potentialPercentage >= pool}
        >
          <Column field="name" title="Subject" rowHeader />
          <Column field="pool" title="Selected Pool Criteria" className="cb-align-center" />
          <Column
            field="potentialPercentage"
            title="Meets Criteria"
            className="cb-align-center"
            component={({ value, row }) =>
              value >= row.pool ? (
                <>
                  <Icon name="check" decorative /> <span className="sr-only">Meets Criteria</span>
                </>
              ) : null
            }
          />
          <Column
            field="potentialPercentage"
            title="Students with Same Score Who Received AP Score of 3+"
            component={({ value }) => `${value}%`}
            className="cb-align-center"
          />
        </Table>
      </div>
    </Loader>
  )
}

export default StudentDetail
