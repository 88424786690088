import React from 'react'
import { normalizeString } from '../../utils/string'

const DetailsSection = ({ details }) => {
  const columnData = details.filter((d) => d)
  const perColumn = Math.ceil(columnData.length / 2)
  const columns = [columnData.slice(0, perColumn), columnData.slice(perColumn)]

  return (
    <div className="row cb-border-bottom cb-padding-bottom-32 cb-margin-bottom-32">
      {columns.map((column, i) => (
        <div key={`column-${i}`} className={`col-sm-6 ${i ? 'cb-margin-xs-top-16' : ''}`}>
          {column.map((c, j) => (
            <React.Fragment key={`row-${j}`}>
              <div
                id={normalizeString(`student-detail-heading-${c.heading}`)}
                className={`cb-roboto-medium ${j ? 'cb-margin-top-16' : ''}`}
              >
                {c.heading}
              </div>
              <div id={normalizeString(`student-detail-value-${c.heading}`)}>{c.content}</div>
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  )
}

DetailsSection.propTypes = {
  details: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DetailsSection
