import { Spinner } from '@cb/apricot-react-spinner'
import classnames from 'classnames'
import Error from '../error/Error'

const Loader = ({ loading, error, loaded, spinnerSize = 'lg', spinnerCenter, loadingClass, errorClass, children }) => {
  if (loading) {
    const centerClasses = spinnerCenter ? 'cb-padding-48 display-flex justify-content-center' : ''
    const classes = classnames(`${centerClasses} cb-spinner-${spinnerSize}`, loadingClass)
    return (
      <div className={classes}>
        <Spinner />
      </div>
    )
  }

  if (error) return <Error {...error} className={errorClass} />

  if (loaded) return children

  return null
}

Loader.propTypes = {
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  }),
  loadingClass: PropTypes.string,
  errorClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  spinnerCenter: PropTypes.bool,
  spinnerSize: PropTypes.oneOf(['sm', 'lg']),
}

export default Loader
