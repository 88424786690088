import { Link, useLocation } from 'react-router-dom'
import { isInternal } from '../../../utils/auth'
import { ADMIN } from '../../../utils/navigation'
import './navigation.scss'

const Navigation = () => {
  const location = useLocation()
  const selectedIndex = ADMIN.findIndex((route) => route.path === location.pathname)
  const selectedNotFound = selectedIndex === -1

  return isInternal() ? (
    <div className="appotential-admin-nav cb-box-shadow-bottom cb-gray5-bg cb-border-top">
      <div className="container display-flex align-items-end">
        <div>Admin:</div>
        <nav className="cb-menu-list cb-menu-list-horizontal" aria-label="Admin Menu">
          <ul>
            {ADMIN.map((route, i) => {
              const selected = i === (selectedNotFound ? 0 : selectedIndex)
              return (
                <li key={route.path}>
                  <Link
                    to={route.path}
                    className={`cb-menu-link ${selected ? 'cb-selected' : ''}`}
                    aria-current={selected}
                  >
                    {route.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  ) : null
}

export default Navigation
