import { Toasts as CBToasts, Toast } from '@cb/apricot-react-toasts'

const Toasts = ({ saves, message, type = 'success', icon = 'check', bottomPosition = false }) => (
  <div aria-live="polite">
    <CBToasts bottom={bottomPosition}>
      {[...Array(saves).keys()].map((a) => (
        <Toast type={type} icon={icon} message={message} key={a} />
      ))}
    </CBToasts>
  </div>
)

Toasts.propTypes = {
  saves: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  bottomPosition: PropTypes.bool,
}

export default Toasts
