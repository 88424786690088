import { MultiSelect } from '@cb/apricot-react'
import { SpacerV } from '@cb/apricot-react-spacer'

const OrgAndSubjectSelections = ({
  availableExams,
  availableOrgs,
  showOrgSelect = true,
  showSubjectSelect = true,
  disabled,
  onChange,
}) => {
  const [selectedExams, setSelectedExams] = useState(availableExams)
  const [selectedOrgs, setSelectedOrgs] = useState(availableOrgs)
  const isSingleOrgSelected = availableOrgs.length === 1
  const isSingleExamSelected = availableExams.length === 1
  const props = { disabled, filter: true, selectAll: true, required: true }

  return (
    <>
      {!isSingleOrgSelected && showOrgSelect ? (
        <>
          <MultiSelect
            label="Organizations"
            onChange={(orgs) => {
              setSelectedOrgs(orgs)
              onChange(orgs, selectedExams)
            }}
            options={availableOrgs}
            fieldName="downloadOrgs"
            selectId="download-orgs"
            value={selectedOrgs}
            helperMsg="You must select at least one org."
            validationMsg="You must select at least one org."
            {...props}
          />
          <SpacerV size={24} />
        </>
      ) : null}
      {!isSingleExamSelected && showSubjectSelect ? (
        <>
          <MultiSelect
            label="AP Subjects"
            onChange={(exams) => {
              setSelectedExams(exams)
              onChange(selectedOrgs, exams)
            }}
            options={availableExams}
            fieldName="downloadExams"
            selectId="download-exams"
            value={selectedExams}
            helperMsg="You must select at least one subject."
            validationMsg="You must select at least one subject."
            {...props}
          />
          <SpacerV size={24} />
        </>
      ) : null}
    </>
  )
}

OrgAndSubjectSelections.propTypes = {
  availableExams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  showOrgSelect: PropTypes.bool,
  showSubjectSelect: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default OrgAndSubjectSelections
