import { Checkbox } from '@cb/apricot-react-forms'
import orderBy from 'lodash/orderBy'
import { QUERY_EDLEVELS } from '../../../services/graphql/queries'
import { useCustomQuery } from '../../../services/graphql/utils'
import { ERROR } from '../../../utils/constants'
import Loader from '../../_common/loader/Loader'

const Grades = ({ selected = [], onChange }) => {
  const { loading, error, data } = useCustomQuery(QUERY_EDLEVELS)
  const formattedGrades = data
    ? orderBy(
        data.map((d) => ({
          label: d.gradeName,
          value: d.code,
          active: Boolean(selected?.find((a) => a === d.code)),
        })),
        'code',
        'asc'
      )
    : []

  return (
    <Loader loaded={Boolean(data)} loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined}>
      <fieldset aria-labelledby="edlevelsGroup">
        <legend id="edlevelsGroup" className="cb-h5 cb-margin-bottom-8">
          Grades
        </legend>
        <p className="cb-font-size-small cb-margin-bottom-16">
          Check the grades you want available for selection in the "Test Criteria" step.
        </p>
        <div className="row">
          {formattedGrades.map((s) => (
            <div className="col-sm-4 col-lg-3 cb-margin-bottom-8" key={s.value}>
              <Checkbox label={s.label} checked={s.active} onChange={(checked) => onChange(s.value, checked)} />
            </div>
          ))}
        </div>
      </fieldset>
    </Loader>
  )
}

Grades.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}
export default Grades
