import { useSubscription } from '@apollo/client'
import { Spinner } from '@cb/apricot-react-spinner'
import { useEffect, useRef } from 'react'
import { SUBSCRIPTION_DOWNLOAD } from '../../../../services/graphql/subscriptions'
import downloadFile from '../../../../services/rest/download'
import { trackError } from '../../../../utils/analytics'

const TOTAL_STEPS = 5
const MAX_DOWNLOAD_TIME = 900000
let timeout

const DownloadProgress = ({ downloadId, onComplete }) => {
  const linkRef = useRef(undefined)
  const [fileData, setFileData] = useState(null)
  const [downloaded, setDownloaded] = useState(false)
  const { data, error } = useSubscription(SUBSCRIPTION_DOWNLOAD, { variables: { downloadId } })
  const { url, step = 0, message } = data?.downloadUpdate || {}
  const percentComplete = `${(step / TOTAL_STEPS) * 100}%`

  useEffect(() => {
    if (downloadId)
      timeout = setTimeout(() => {
        const msg = 'Download timed out.'
        onComplete(msg)
        trackError(msg)
      }, MAX_DOWNLOAD_TIME)
    return () => clearTimeout(timeout)
  }, [onComplete, downloadId])

  useEffect(() => {
    const download = async () => {
      if (url) {
        const { data, error } = await downloadFile(url)
        if (data) setFileData(data)
        if (error) onComplete(error)
        if (onComplete && downloaded) onComplete(error)
      }
    }

    download()
  }, [url, onComplete, downloaded])

  useEffect(() => {
    if (error) onComplete(error)
  }, [error, onComplete])

  useEffect(() => {
    if (message && message.includes('Error')) onComplete(message)
  }, [message, onComplete])

  useEffect(() => {
    if (fileData) {
      linkRef?.current?.click()
      setDownloaded(true)
    }
  }, [fileData])

  return (
    <div className="display-flex justify-content-center cb-spacerv-bottom-24">
      <Spinner
        timeout={MAX_DOWNLOAD_TIME}
        show
        text={percentComplete}
        ariaLabel={`Download ${percentComplete} complete`}
      />
      <a ref={linkRef} href={fileData} download={url?.split('/').pop().split('?')[0]} className="display-none" />
    </div>
  )
}

DownloadProgress.propTypes = {
  downloadId: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
}

export default DownloadProgress
