import { isEmpty, isNil, omit } from 'ramda'
import { useEffect } from 'react'
import { QUERY_ASSESSMENT_EVENTS, QUERY_EDPERIODS } from '../../../services/graphql/queries'
import { useCustomLazyQuery, useCustomQuery } from '../../../services/graphql/utils'
import Loader from '../../_common/loader/Loader'
import AssessmentActions from './AssessmentActions'
import AssessmentsTable from './AssessmentTable'

const Assessments = () => {
  // selectedEvents variable indicate current or previous checked/selected assessments
  const [selectedEvents, setSelectedEvents] = useState([])
  const edPeriod = useCustomQuery(QUERY_EDPERIODS)
  const events = useCustomLazyQuery(QUERY_ASSESSMENT_EVENTS)
  const [eventsData, setEventsData] = useState([]) // eventsData is records with spreaded out releaseWindows
  const [isCheckboxTouched, setIsCheckboxTouched] = useState(false)

  // update all assessments instead of filtering them; pendingUpdates variable is pushed to AssessmentActions component for saving
  // merge selectedEvents to events.data
  const getPendingUpdates = () =>
    events.data?.reduce((acc, original) => {
      const originalReleaseWindows = JSON.parse(original.releaseWindows) || []

      if (isEmpty(originalReleaseWindows)) {
        // if releaseWindows is empty, take active from record.active
        const updated = {
          ...original,
          releaseWindows: JSON.stringify([]),
          active: Boolean(selectedEvents?.find((modified) => modified.asmtEventId === original.asmtEventId)?.active),
        }
        return [...acc, updated]
      }
      // if releaseWindows is NOT empty, take active from record.releaseWindow.active and aggregate the record
      const modifiedWindowObj = originalReleaseWindows.reduce(
        (acc, originalReleaseWindow) => {
          const selectedEventPerWindow = selectedEvents?.find(
            (modified) =>
              modified.asmtEventId === original.asmtEventId &&
              modified.releaseWindow.k12ScoreReleaseTs === originalReleaseWindow.k12ScoreReleaseTs
          )

          if (!acc.active) {
            // either one of the releaseWindows is active, the record is active
            acc.active = Boolean(selectedEventPerWindow?.active)
          }

          if (selectedEventPerWindow) {
            // if selectedEventPerWindow is not undefined, it has changed
            acc.releaseWindows.push(selectedEventPerWindow.releaseWindow) // add modified releaseWindow
          } else {
            acc.releaseWindows.push({ ...originalReleaseWindow, active: false }) // add original releaseWindow and set active to false
          }

          return acc
        },
        { active: false, releaseWindows: [] }
      )

      return [
        ...acc,
        { ...original, ...modifiedWindowObj, releaseWindows: JSON.stringify(modifiedWindowObj.releaseWindows) },
      ]
    }, [])

  // run 1 time when events.data is loaded
  useEffect(() => {
    const allModified = eventsData?.length > 0 && eventsData.every((e) => !isNil(e.releaseWindow))

    if (!events.loading && !allModified) {
      // when events.loading is false, update eventsData to spread out releaseWindows
      setEventsData(
        events.data?.reduce((acc, d) => {
          const releaseWindows = JSON.parse(d?.releaseWindows) || []
          if (releaseWindows.length === 0) {
            return [...acc, omit(['releaseWindows'], { ...d, releaseWindow: {} })]
          }
          const eventWithWindows = releaseWindows.map((releaseWindow, index) => ({
            ...omit(['releaseWindows', 'active'], d), // remove record.releaseWindows and record.active (use record.releaseWindow.active)
            releaseWindow,
          }))
          return [...acc, ...eventWithWindows]
        }, [])
      )
    }
  }, [eventsData, setEventsData, events, events.loading])

  // set 1 time when events.data turns to modified eventsData
  useEffect(() => {
    const checkedEvents =
      eventsData?.filter((e) => {
        if (isEmpty(e.releaseWindow)) {
          // if releaseWindow is empty, take active from record.active
          return e.active
        }
        // if releaseWindow is NOT empty, take active from record.releaseWindow.active
        return e.releaseWindow.active
      }) || []
    setSelectedEvents(checkedEvents)
  }, [eventsData])

  // onEventUpdate function is called when user checks/unchecks an assessment
  const onEventUpdate = (updates) => {
    // updates is an array of selected assessments
    const checkedEvents =
      updates?.reduce((acc, e) => {
        if (isEmpty(e.releaseWindow)) {
          // if releaseWindow is empty
          e.active = true
          acc.push(e)
        } else {
          // if releaseWindow is NOT empty
          e.releaseWindow.active = true
          e.active = true
          acc.push(e)
        }
        return acc
      }, []) || []
    setIsCheckboxTouched(true)
    setSelectedEvents(checkedEvents)
  }

  const onEdPeriodSelection = (value) => {
    if (value) {
      events.fetch({ variables: { educationPeriodCd: value } })
      setEventsData([])
    }
  }

  const getErrorProps = (message, container) =>
    message ? { title: 'Unable to Load Assessments', message, container } : undefined

  return (
    <Loader
      loaded={Boolean(edPeriod.data)}
      loading={edPeriod.loading}
      spinnerCenter
      error={getErrorProps(edPeriod.error)}
    >
      <div className="container">
        <h1 className="cb-h3 cb-roboto-light cb-margin-bottom-24">Assessments</h1>
        <AssessmentActions
          getPendingUpdates={getPendingUpdates}
          onEdPeriodSelection={onEdPeriodSelection}
          currentEdPeriod={edPeriod.data?.find((d) => d.current)?.code}
          isCheckboxTouched={isCheckboxTouched}
          setIsCheckboxTouched={setIsCheckboxTouched}
        />
        <Loader loading={events.loading} error={getErrorProps(events.error, false)} spinnerCenter loaded>
          <AssessmentsTable events={eventsData} selectedEvents={selectedEvents} onSelection={onEventUpdate} />
        </Loader>
        <div className="cb-margin-top-24">
          {eventsData ? (
            <AssessmentActions
              isCheckboxTouched={isCheckboxTouched}
              setIsCheckboxTouched={setIsCheckboxTouched}
              getPendingUpdates={getPendingUpdates}
            />
          ) : null}
        </div>
      </div>
    </Loader>
  )
}

export default Assessments
