// global apricot css imports
import '@cb/apricot/CBBand'
import '@cb/apricot/CBBase'
import '@cb/apricot/CBButton'
import '@cb/apricot/CBCard'
import '@cb/apricot/CBForm'
import '@cb/apricot/CBGlyph'
import '@cb/apricot/CBGrid'
import '@cb/apricot/CBLogo'
import '@cb/apricot/CBMenuList'

// global app scss imports
import './assets/scss/apricot-helpers.scss'

import get from 'lodash/get'
import set from 'lodash/set'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './components/app/App'
import ErrorBoundary from './components/app/ErrorBoundary'
import { CRITERIA_STORAGE_KEY } from './services/session/findstudents'
import { hasLoginCookie } from './utils/auth'
import { APP_CONFIG, HIDE_EVENT, SHOW_EVENT } from './utils/constants'
import initPolyfills from './utils/polyfills'
import { removeSessionItem } from './utils/storage'

let appContainer = null
let root = null
initPolyfills()

const destroy = () => appContainer && root?.unmount()

const toggleLogoutContainers = (show) => {
  const { options } = get(window, APP_CONFIG)
  options.logoutContainers.forEach((c) => (c.style.display = show ? 'none' : 'block'))
  if (!show) {
    // clear session storage on logout, cb event bus logout event is not fired correctly
    removeSessionItem(CRITERIA_STORAGE_KEY)
  }
}

const toggle = (show) => {
  const event = new CustomEvent(show ? SHOW_EVENT : HIDE_EVENT, {
    bubbles: true,
  })
  appContainer.dispatchEvent(event)
  toggleLogoutContainers(show)
}

// container = #app tag; logoutContainer = #appotential-logout-content tag
const init = (container, { logoutContainer }) => {
  const isLoggedIn = hasLoginCookie()
  const containers = logoutContainer
    ? logoutContainer.split(', ').reduce((acc, c) => [...acc, ...document.querySelectorAll(c)], [])
    : []

  appContainer = document.querySelector(container)
  root = createRoot(appContainer)

  set(window, `${APP_CONFIG}.options.logoutContainers`, containers)
  toggleLogoutContainers(true)

  if (isLoggedIn) toggle(true)

  // show breakpoint information, dev use only
  if (Config.LOCAL) document.body.classList.add('cb-dev-env', 'cb-show-breakpoints')

  root.render(
    <>
      <ErrorBoundary>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </>
  )
}

set(window, APP_CONFIG, {
  init,
  destroy,
  toggle,
  events: { show: SHOW_EVENT, hide: HIDE_EVENT },
})
