import axios from 'axios'
import { trackError } from '../../utils/analytics'
import { getAuthenticationToken, getAuthorizationToken } from '../../utils/auth'

export default async (url) => {
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'x-cb-catapult-authorization-token': getAuthorizationToken(),
        'x-cb-catapult-authentication-token': getAuthenticationToken(),
      },
    })
    return { data: URL.createObjectURL(new Blob([data], { type: 'application/zip' })) }
  } catch (err) {
    trackError('Download file error')
    return { error: err.message || 'Error: Unable to download file.' }
  }
}
