import { MultiSelect } from '@cb/apricot-react'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect } from 'react'
import { QUERY_AP_COUNTS } from '../../../../../services/graphql/queries'
import { useCustomLazyQuery } from '../../../../../services/graphql/utils'
import { getExamOrgVariables, updateCriteria } from '../../../../../services/session/findstudents'

const OPTION_ALL = { label: 'Select All AP Subjects', value: 'ALL' }
const OPTION_TAKEN = { label: 'Select AP Subjects offered at my school', value: 'TAKEN' }
const OPTION_NOT_TAKEN = { label: 'Select AP Subjects not offered at my school', value: 'NOT_TAKEN' }

const SelectExams = ({ criteria, activeApAsmts = [], educationPeriodCd }) => {
  const { exams = [] } = criteria //  exams aka selected exams
  const { fetch: fetchAPCounts, ...apCounts } = useCustomLazyQuery(QUERY_AP_COUNTS)

  const updateSelectedExams = useCallback(
    (updates) => !isEqual(updates, exams) && updateCriteria({ exams: updates, edlevels: {} }),
    [exams]
  )

  useEffect(() => {
    if (educationPeriodCd)
      fetchAPCounts({
        variables: {
          ...getExamOrgVariables(),
          apAsmts: activeApAsmts.map((a) => a.value),
          educationPeriodCd,
        },
      })
  }, [fetchAPCounts, activeApAsmts, educationPeriodCd])

  const getOptions = () => {
    const { data, error } = apCounts
    const asmts = [...activeApAsmts] // asmts aka available options
    const [testsTaken = [], testsNotTaken = []] = apCounts.data
      ? apCounts.data.reduce(
          (acc, d) => {
            const { totalTestTakers, apAsmtId } = d
            const index = totalTestTakers ? 0 : 1
            acc[index] = [...acc[index], apAsmtId]
            return acc
          },
          [[], []]
        )
      : []

    const combineSelections = (checked, arr) =>
      checked
        ? activeApAsmts.filter((a) => arr.includes(a.value) || exams.find((e) => e.value === a.value))
        : exams.filter((e) => !arr.includes(e.value))

    if (data || error) {
      const addOption = (option, arr) =>
        asmts.unshift({
          ...option,
          className: 'cb-font-weight-medium',
          isSubSelectAll: true,
          isChecked: arr.every((a) => exams.find((e) => e.value === a)),
          onChange: (checked) => updateSelectedExams(combineSelections(checked, arr)),
        })

      if (testsNotTaken.length) addOption(OPTION_NOT_TAKEN, testsNotTaken)
      if (testsTaken.length) addOption(OPTION_TAKEN, testsTaken)
      if (testsTaken.length || testsTaken.length) addOption(OPTION_ALL, [...testsTaken, ...testsNotTaken])
    }

    return asmts
  }

  return (
    <MultiSelect
      label="AP Subjects"
      onChange={updateSelectedExams}
      options={getOptions()}
      fieldName="exams-select"
      selectId="exams-select"
      value={exams}
      disabled={apCounts.loading}
      required
      selectAll={false} // choose not to use the native selectAll option because of styling and functionality changed. populate selectAll options manually
      filter
      tags
      helperMsg="You must select at least one subject."
      validationMsg="You must select at least one subject."
    />
  )
}

SelectExams.propTypes = {
  criteria: PropTypes.object.isRequired,
  educationPeriodCd: PropTypes.number,
  activeApAsmts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

export default SelectExams
