import { MultiSelect } from '@cb/apricot-react'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { QUERY_DISTRICT_ORGS } from '../../../../../services/graphql/queries'
import { useCustomLazyQuery } from '../../../../../services/graphql/utils'
import { ERROR } from '../../../../../utils/constants'
import Loader from '../../../../_common/loader/Loader'

const SchoolMultiselect = ({ selections, district, update }) => {
  const { fetch, loading, error, data } = useCustomLazyQuery(QUERY_DISTRICT_ORGS)
  const [districtOrg, setDistrictOrg] = useState(district)

  useEffect(() => {
    if (!isEqual(districtOrg, district)) setDistrictOrg(district)
  }, [district, districtOrg])

  useEffect(() => {
    if (districtOrg?.orgId) fetch({ variables: { district: districtOrg?.orgId } })
  }, [districtOrg, fetch])

  return (
    <Loader loaded={Boolean(data)} loading={loading} spinnerSize="sm" error={error ? ERROR.DEFAULT : undefined}>
      {data?.length ? (
        <MultiSelect
          label="School(s)"
          onChange={(orgs) => update({ selections: orgs, district: { ...district, all: orgs.length === data.length } })}
          options={data.map((d) => ({
            label: `${d.orgName} (${d.aiCode})`,
            value: d.orgId,
          }))}
          fieldName="district-school-selects"
          selectId="district-school-selects"
          value={selections}
          required
          selectAll
          filter
          tags
          helperMsg="You must select at least one school."
          validationMsg="You must select at least one school."
        />
      ) : (
        <p>There are no organizations available for this district.</p>
      )}
    </Loader>
  )
}

SchoolMultiselect.propTypes = {
  district: PropTypes.object,
  selections: PropTypes.array,
  update: PropTypes.func.isRequired,
}

export default SchoolMultiselect
