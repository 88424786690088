import memoize from 'memoize'

/** Helper function to convert array into an object
 * @param {Array} data
 * @param {Function} itemFn (returns object for a single element that will be merged into the larger object)
 * @returns object
 */
export const objectify = memoize((arr, itemFn) => (arr ? arr.reduce((obj, s) => ({ ...obj, ...itemFn(s) }), {}) : []), {
  cacheKey: JSON.stringify,
})

export const combineFields = (fields = [], separator = ' ') => fields.filter((f) => f).join(separator)
