import axios from 'axios'
import orderBy from 'lodash/orderBy'
import { trackError } from '../../utils/analytics'
import { checkOrgAccess, isState } from '../../utils/auth'
import getEnvironment from '../../utils/environment'

export const SEARCH_TYPE_NAME = 'name'
export const SEARCH_TYPE_AICODE = 'code'
export const SEARCH_TYPE_ORGID = 'orgid'
export const SEARCH_TYPE_STATE = 'state'

const MIN_CHARACTERS = 3
const MAX_RETURNED_RESULT = 10
const DEFAULT = { results: [], total: 0 }
const HOMESCHOOL_ORG_TYPE = '9'
const HOMESCHOOL_ORG_SUB_TYPE = '12'
const DISTRICT_RELATION = 'STD_K12_ADMIN'
const DISTRICT_ORG_TYPE = '6'
const DISTRICT_ORG_SUB_TYPES = [
  '3',
  '4',
  '6',
  '7',
  '8',
  '9',
  '13',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '30',
  '36',
  '38',
  '39',
  '40',
  '54',
  '55',
]
const PRIVATE_SCHOOL_ORG_SUB_TYPE_CODES = ['2', '11', '45'] // org_sub_type_code of 2 (Catholic school), 11 (Private School, Non-Religious) and 45 (Private School, Religious Affiliation)
const K12_ORG_TYPE_CODE = '1'

const shouldIncludeSchool = (org_type_cd, org_sub_type_cd) => {
  if (isState()) {
    return org_type_cd === K12_ORG_TYPE_CODE && !PRIVATE_SCHOOL_ORG_SUB_TYPE_CODES.includes(org_sub_type_cd)
  }
  return true
}

export default async (searchTerm, searchType, states = {}, regions = {}) => {
  const { cbUrl, cbApiKey } = getEnvironment()

  if (searchTerm.length < MIN_CHARACTERS) return DEFAULT

  try {
    const {
      data: {
        hits: { total, hits },
      },
    } = await axios({
      method: 'get',
      url: `${cbUrl}/orgsearch`,
      params: {
        [searchType]: searchTerm,
        size: 25,
      },
      withCredentials: false,
      headers: { 'X-Api-Key': cbApiKey },
    })

    return {
      total,
      results: hits
        .reduce((arr, { _source: h }) => {
          const { ai_code: aiCode } = (h.ais && h.ais[0]) || {}
          const parent = orderBy(h.hierarchy, 'edu_period_cd', 'desc').find((h) => h.org_relation === DISTRICT_RELATION)

          const allowedAI =
            aiCode &&
            h.org_type_cd !== HOMESCHOOL_ORG_TYPE &&
            h.org_sub_type_cd !== HOMESCHOOL_ORG_SUB_TYPE &&
            shouldIncludeSchool(h.org_type_cd, h.org_sub_type_cd)

          const allowedDistrict =
            h.org_type_cd === DISTRICT_ORG_TYPE && DISTRICT_ORG_SUB_TYPES.includes(h.org_sub_type_cd)

          const hasAccess = checkOrgAccess(states[h.org_state_cd], regions[h.org_region], parent?.org_parent, h.org_id)
          const include =
            (searchType === SEARCH_TYPE_ORGID
              ? allowedDistrict
              : searchType === SEARCH_TYPE_AICODE
                ? allowedAI
                : allowedAI || allowedDistrict) && hasAccess

          return include
            ? [
                ...arr,
                {
                  orgId: h.org_id,
                  orgName: h.org_full_name,
                  city: h.org_city || '',
                  stateCd: h.org_state_cd || '',
                  countryCd: h.org_country_iso_cd || '',
                  address: h.org_street_addr1 || '',
                  postalCd: h.org_zip5 || h.org_intl_postal_code || '',
                  regionCd: h.org_region,
                  aiCode,
                  isDistrict: !aiCode || allowedDistrict,
                },
              ]
            : arr
        }, [])
        .slice(0, MAX_RETURNED_RESULT),
    }
  } catch (err) {
    console.error(err)
    trackError('Org search failed')
    return DEFAULT
  }
}
