import { useEffect } from 'react'
import { QUERY_EDLEVELS, QUERY_ETHNICITIES, QUERY_GENDERS } from '../../../../services/graphql/queries'
import { useLookupQuery } from '../../../../services/graphql/utils'
import { DOWNLOADS, trackDownload } from '../../../../utils/analytics'
import { formatDate, FORMATS } from '../../../../utils/date'
import { combineFields } from '../../../../utils/misc'
import DownloadCSV from '../../../_common/downloadcsv/DownloadCSV'

// eslint-disable-next-line prefer-template
const formatLeadingZeroesForExcel = (str) => (str ? '=""' + str + '""' : '')

const transformDataForCSV = (data, edLevels, ethnicities, genders, exams) =>
  data.reduce((arr, d) => {
    const potential = exams.reduce(
      (obj, e) => ({ ...obj, [`exam${e.value}`]: d.evaluatedCutScores[e.value] ? 'Y' : '' }),
      {}
    )
    const hasPotential = Object.values(potential).filter((p) => p).length
    return hasPotential
      ? [
          ...arr,
          {
            aiCode: formatLeadingZeroesForExcel(d.aiCode),
            lastName: d.lastName,
            firstName: combineFields([
              d.firstName,
              d.middleInitial,
              d.preferredName ? `(Preferred First Name: ${d.preferredName})` : '',
            ]),
            studentSchoolId: formatLeadingZeroesForExcel(d.studentSchoolId),
            birthDate: formatDate(d.birthDate, FORMATS.standarddate),
            address: d.address ? combineFields([d.address.streetAddr1, d.address.streetAddr2], ', ') : '',
            state: d.address?.stateCd,
            city: d.address?.city,
            zip: formatLeadingZeroesForExcel(d.address?.postalCd),
            ethnicity: ethnicities[d.ethnicityCd],
            gender: genders[d.genderCd],
            educationLevel: edLevels[d.educationLevelCd],
            ...potential,
          },
        ]
      : arr
  }, [])

const DownloadRosterButton = ({ data = [], org, exams, pools, assessment, disabled, onDownload }) => {
  const [csvData, setCSVData] = useState(undefined)
  const edLevels = useLookupQuery(QUERY_EDLEVELS, ['code', 'gradeName'])
  const ethnicities = useLookupQuery(QUERY_ETHNICITIES, ['code', 'ethnicityName'])
  const genders = useLookupQuery(QUERY_GENDERS, ['code', 'genderName'])
  const loading = Boolean(!csvData)
  const year = assessment.label.replace(/ /g, '-')

  const headers = [
    { key: 'aiCode', label: 'School Code' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'firstName', label: 'First Name' },
    { key: 'studentSchoolId', label: 'SID' },
    { key: 'birthDate', label: 'Date of Birth' },
    { key: 'address', label: 'Address' },
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'zip', label: 'Zip' },
    { key: 'ethnicity', label: 'Ethnicity' },
    { key: 'gender', label: 'Gender' },
    { key: 'educationLevel', label: 'Grade Level' },
    ...exams.map((e) => ({ key: `exam${e.value}`, label: `${e.label} (${pools[e.value]})` })),
  ]

  useEffect(() => {
    if (edLevels.data && ethnicities.data && genders.data)
      setCSVData(transformDataForCSV(data, edLevels.data, ethnicities.data, genders.data, exams))
  }, [edLevels.data, ethnicities.data, genders.data, data, exams])

  return (
    <DownloadCSV
      data={csvData}
      headers={headers}
      filename={`AP-Potential-Roster-${org.label.replace(/ /g, '-')}-${year}`}
      loading={loading}
      disabled={loading || disabled}
      onClick={(e) => {
        if (onDownload) onDownload(e)
        trackDownload(DOWNLOADS.csv)
      }}
    />
  )
}

DownloadRosterButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  pools: PropTypes.object.isRequired,
  org: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  assessment: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onDownload: PropTypes.func,
}

export default DownloadRosterButton
