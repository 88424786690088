import { Input, RadioButton, RadioButtonGroup } from '@cb/apricot-react-forms'
import { SpacerV } from '@cb/apricot-react-spacer'
import { useEffect } from 'react'
import { QUERY_DOWNLOAD_LETTERS } from '../../../../services/graphql/queries'
import { getSearchVariables } from '../../../../services/session/findstudents'
import { DOWNLOADS } from '../../../../utils/analytics'
import FormIntro from '../../../_common/forms/formintro/FormIntro'
import DownloadButtons from '../_common/DownloadButtons'
import OrgAndSubjectSelections from '../_common/OrgAndSubjectSelections'

const OPTION_ENGLISH = 'english'
const OPTION_ESPANOL = 'espanol'

const allSelected = (obj) => Object.values(obj).every((s) => s.length || typeof s === 'boolean')

const LettersForm = ({ exams = [], availableOrgs = [], closeModal }) => {
  const [processing, setProcessing] = useState(false)
  const [selected, setSelected] = useState({ exams, orgs: availableOrgs })
  const [contact, setContact] = useState({ contact: '', signature: '', espanol: false })
  const variables =
    allSelected(selected) && allSelected(contact)
      ? {
          ...getSearchVariables(
            selected.orgs.map((o) => o.value),
            selected.exams.map((e) => e.value)
          ),
          ...contact,
        }
      : undefined

  const onChange = (selectedOrgs, selectedExams) => setSelected({ exams: selectedExams, orgs: selectedOrgs })

  const updateContact = (update) => setContact((curr) => ({ ...curr, ...update }))

  useEffect(() => {
    setSelected((curr) => ({
      exams: exams || curr.exams,
      orgs: availableOrgs || curr.orgs,
    }))
  }, [exams, availableOrgs])

  return (
    <>
      <FormIntro
        text="Create a mail merge of letters you can send to parents/guardians and students, based upon the student roster you
        generated. Send these letters to inform families of their child's potential to succeed in specific AP courses.
        The letters are generated only for AP courses for which students have the level of AP Potential you selected."
        required
      />
      <OrgAndSubjectSelections
        availableExams={exams}
        availableOrgs={availableOrgs}
        onChange={onChange}
        disabled={processing}
      />
      <Input
        name="contact"
        label="Contact"
        id="contact"
        labelId="contact-label"
        buttonId="contact-button"
        floating
        validation="general"
        required
        onChange={(e) => updateContact({ contact: e.target.value })}
        validationMsg="ex: Jane Doe at 301-555-1212, or Jane Doe at janedoe@example.com"
        disabled={processing}
      />
      <SpacerV size={24} />
      <Input
        name="signature"
        label="Signature"
        id="signature"
        labelId="signature-label"
        buttonId="signature-button"
        floating
        validation="general"
        required
        onChange={(e) => updateContact({ signature: e.target.value })}
        validationMsg="ex: Jane Doe"
        disabled={processing}
      />
      <SpacerV size={24} />
      <RadioButtonGroup
        required
        legend="Language"
        legendClassName="cb-text"
        name="language"
        defaultValue={OPTION_ENGLISH}
        vertical
        onChange={(value) => updateContact({ espanol: value === OPTION_ESPANOL })}
      >
        <RadioButton id="english" label="English" value={OPTION_ENGLISH} disabled={processing} />
        <RadioButton id="spanish" label="Spanish" value={OPTION_ESPANOL} disabled={processing} />
      </RadioButtonGroup>
      <DownloadButtons
        query={QUERY_DOWNLOAD_LETTERS}
        variables={variables}
        onCancel={closeModal}
        onDownload={closeModal}
        onProcessing={setProcessing}
        title="Generate and Download"
        buttonId="download"
        downloadName={contact.espanol ? DOWNLOADS.letteresp : DOWNLOADS.lettereng}
      />
    </>
  )
}

LettersForm.propTypes = {
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  closeModal: PropTypes.func.isRequired,
}

export default LettersForm
