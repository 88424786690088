import { useMutation } from '@apollo/client'
import { PrimaryButton } from '@cb/apricot-react-button'
import { Select } from '@cb/apricot-react-forms'
import { MUTATION_SAVE_ASSESSMENTS } from '../../../services/graphql/mutations'
import { ERROR } from '../../../utils/constants'
import { getEducationPeriodDescr } from '../../../utils/date'
import Error from '../../_common/error/Error'
import Toasts from '../../_common/toasts/Toasts'

const VISIBLE_YEARS = 6

// pendingUpdates will be the full list of assessment events
const AssessmentActions = ({
  currentEdPeriod,
  onEdPeriodSelection,
  getPendingUpdates,
  isCheckboxTouched = false,
  setIsCheckboxTouched,
}) => {
  const [saves, updateSaves] = useState(0)
  const [save, { loading: saving, error: saveError }] = useMutation(MUTATION_SAVE_ASSESSMENTS, {
    update(cache) {
      cache.modify({
        fields: {
          getAssessmentEvents(_existing = []) {
            return getPendingUpdates()
          },
        },
      })
    },
    onCompleted: () => updateSaves(saves + 1),
  })

  const edPeriodOptions = [...Array(VISIBLE_YEARS).keys()].map((index) => ({
    value: currentEdPeriod - index,
    label: getEducationPeriodDescr(currentEdPeriod - index),
  }))

  const onClick = () => {
    const updates = getPendingUpdates()
    save({
      variables: {
        assessments: updates.map(
          ({ __typename, ...strippedUpdates }) => strippedUpdates // omitted some columns
        ),
      },
    })
    setIsCheckboxTouched(false)
  }

  return (
    <>
      <div className="row cb-margin-bottom-24">
        <div className="col-sm-6 col-md-4">
          {currentEdPeriod ? (
            <Select
              values={edPeriodOptions}
              label="Education Periods"
              labelNoValue="Select Education Period"
              name="edperiods"
              floating
              onChange={(value) => {
                const period = parseInt(value, 10)
                onEdPeriodSelection(period)
                setIsCheckboxTouched(false)
              }}
            />
          ) : null}
        </div>
        <div className="col-sm-6 col-md-8 cb-align-right cb-align-xs-left cb-margin-xs-top-16">
          <PrimaryButton loading={saving} disabled={!isCheckboxTouched || saving} onClick={onClick}>
            Update Assessments
          </PrimaryButton>
          <Toasts message="Assessments successfully updated" saves={saves} />
        </div>
      </div>
      {saveError ? (
        <Error
          className="cb-margin-bottom-24"
          container={false}
          message={`${ERROR.ADMIN_SAVE_ASSESSMENTS.message} ${saveError}`}
        />
      ) : null}
    </>
  )
}

AssessmentActions.propTypes = {
  currentEdPeriod: PropTypes.number,
  onEdPeriodSelection: PropTypes.func,
  getPendingUpdates: PropTypes.func,
  isCheckboxTouched: PropTypes.bool,
  setIsCheckboxTouched: PropTypes.func,
}

export default AssessmentActions
