import { getAnalyticsData } from '../services/session/findstudents'
import {
  getRoles,
  ROLE_DISTRICT,
  ROLE_INTERNAL,
  ROLE_K12DETAILEDREPORTS,
  ROLE_REGION,
  ROLE_SCHOOL,
  ROLE_STATE,
} from './auth'
import { DEFAULT_ALL } from './constants'
import { APP_LINKS } from './navigation'

const TYPE_VIEW = 'cbTrack.viewInDom'
const TYPE_ERROR = 'cbTrack.appError'

const getRoleTitle = (roleId) => {
  switch (roleId) {
    case ROLE_SCHOOL:
    case ROLE_K12DETAILEDREPORTS: // k12 detailed reports role means "school user" in context of APP
      return 'school'
    case ROLE_DISTRICT:
      return 'district'
    case ROLE_STATE:
      return 'state'
    case ROLE_REGION:
      return 'regional'
    case ROLE_INTERNAL:
      return 'internal'
    default:
      return ''
  }
}

export const PAGE_CODES = {
  roster: 'student-roster',
  detail: 'student-detail',
  subject: 'subject-detail',
  organization: 'select-organization',
  testcriteria: 'select-test-criteria',
  studentpool: 'select-student-pools',
  forbidden: '403forbidden',
  catapult: '500errorpage',
}

export const DOWNLOADS = {
  csv: 'student-roster.csv',
  lettereng: 'sample-letter-eng.pdf',
  letteresp: 'sample-letter-span.pdf',
}

const setUser = () => {
  const [roles, orgs] = getRoles().reduce(
    (arr, role) => [
      [...arr[0], getRoleTitle(role.rid)],
      [...arr[1], role.oid],
    ],
    [[], []]
  )
  return { user: { role: roles.join('|'), orgId: orgs.join('|') } }
}

const callAnalytics = (appDataLayer, trackType = TYPE_VIEW) => {
  try {
    const user = setUser()
    const apPotentialRoster = getAnalyticsData()
    const combined = { apPotentialRoster, ...user, ...appDataLayer }
    kiwi?.mergeDdl(combined)
    _satellite?.track(trackType)
  } catch (errKiwiMergeDdl) {
    try {
      _satellite?.logger?.error(`Something went wrong with kiwi.mergeDdl(): ${errKiwiMergeDdl.message}`)
    } catch (errSatelliteLoggerError) {}
  }
}

export const trackPage = (route, pageCode) => {
  const page = Object.entries(APP_LINKS).find((entry) => route === entry[1])
  if (page || pageCode) callAnalytics({ page: { pageCode: pageCode || PAGE_CODES[page[0]] } })
  else console.warn(`Route not tracked: ${route}`)
}

export const trackSubjectSelection = (value) => {
  callAnalytics({ page: { pageCode: value === DEFAULT_ALL ? PAGE_CODES.roster : PAGE_CODES.subject } })
}

export const trackError = (message, code = 500) => callAnalytics({ appError: { message, code } }, TYPE_ERROR)

export const trackDownload = (downloadName) => {
  document.dispatchEvent(
    new CustomEvent('cbTrack-downloadClick', {
      bubbles: true,
      detail: {
        downloadName,
      },
    })
  )
}
