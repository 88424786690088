import { Link } from 'react-router-dom'
import { combineFields } from '../../../../utils/misc'
import { getStudentDetailPath } from '../../../../utils/navigation'

const StudentColumn = ({ catapultId, firstName, lastName, middleInitial, studentSchoolId, preferredName }) => (
  <>
    <Link to={getStudentDetailPath(catapultId)}>
      {lastName}, {firstName} {middleInitial}
    </Link>{' '}
    {studentSchoolId || preferredName
      ? `(${combineFields(
          [
            preferredName ? `Preferred First Name: ${preferredName}` : '',
            studentSchoolId ? `ID: ${studentSchoolId}` : '',
          ],
          ', '
        )})`
      : null}
  </>
)

StudentColumn.propTypes = {
  catapultId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  middleInitial: PropTypes.string,
  studentSchoolId: PropTypes.string,
  preferredName: PropTypes.string,
}

export default StudentColumn
