import { Checkbox } from '@cb/apricot-react-forms'
import orderBy from 'lodash/orderBy'
import { QUERY_AP_ASSESSMENTS, QUERY_CUTSCORES } from '../../../services/graphql/queries'
import { useCustomQuery, useLookupQuery } from '../../../services/graphql/utils'
import { ERROR } from '../../../utils/constants'
import Loader from '../../_common/loader/Loader'

const Subjects = ({ selected = [], onChange }) => {
  const cuts = useLookupQuery(QUERY_CUTSCORES, ['apAsmtId'])
  const exams = useCustomQuery(QUERY_AP_ASSESSMENTS)
  const loaded = Boolean(cuts.data && exams.data)
  const loading = cuts.loading || exams.loading
  const error = cuts.error || exams.error

  const formattedExams = loaded
    ? orderBy(
        exams.data.map((d) => ({
          label: d.asmtName,
          apAsmtId: d.apAsmtId,
          active: Boolean(selected?.find((a) => a === d.apAsmtId)),
          disabled: !Boolean(cuts.data[d.apAsmtId]),
        })),
        'label',
        'asc'
      )
    : []

  return (
    <Loader loaded={loaded} loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined}>
      <fieldset aria-labelledby="subjectsGroup">
        <legend id="subjectsGroup" className="cb-h5 cb-margin-bottom-8">
          AP Subjects
        </legend>
        <p className="cb-font-size-small cb-margin-bottom-16">
          Check the subjects you want available for selection in the "Test Criteria" step. Disabled subjects do not have
          cut scores available.
        </p>
        <div className="row">
          {formattedExams.map((s) => (
            <div className="col-lg-4 col-sm-6 cb-margin-bottom-8" key={s.apAsmtId}>
              <Checkbox
                label={s.label}
                checked={s.active}
                disabled={s.disabled}
                onChange={(checked) => onChange(s.apAsmtId, checked)}
              />
            </div>
          ))}
        </div>
      </fieldset>
    </Loader>
  )
}

Subjects.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

export default Subjects
