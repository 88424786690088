import { QUERY_REGIONS, QUERY_STATES } from '../../../../../services/graphql/queries'
import { useLookupQuery } from '../../../../../services/graphql/utils'
import searchByOrg from '../../../../../services/rest/orgsearch'
import Autocomplete from '../../../../_common/forms/autocomplete/Autocomplete'

const OrgAutocomplete = ({ district, selections = [], update, type }) => {
  const regions = useLookupQuery(QUERY_REGIONS, ['regionCd', 'orgId'])
  const states = useLookupQuery(QUERY_STATES, ['stateCd', 'orgId'])

  const getLabel = (item) => `${item.orgName} (${item.aiCode || item.orgId})`

  return (
    <Autocomplete
      key={`force-refresh-key-${type}`}
      helperMsg={`Start typing to search for a school or district`}
      errMsg={`Error: You must select a school or district from the dropdown`}
      isValid={Boolean(selections.length || district)}
      label={'Organization'}
      fieldName="org"
      fetch={async (term) => {
        update({ selections: [], district: undefined })
        const { results } = await searchByOrg(term, type, states.data, regions.data)
        return results
      }}
      defaultValue={district?.orgName || selections[0]?.label || ''}
      disabled={regions.loading || states.loading}
      required
      onSelection={(org) => {
        if (org?.isDistrict) {
          update({ district: org, selections: undefined })
        } else {
          update({
            selections: org ? [{ label: getLabel(org), value: org.orgId }] : undefined,
            district: undefined,
          })
        }
      }}
      onBuild={(item) => ({
        label: getLabel(item),
        value: item.orgId,
        children: `<div><strong>${getLabel(item)}</strong></div><div>${item.address ? `${item.address}, ` : ''}${
          item.city
        } ${item.stateCd || item.countryCd} ${item.postalCd}</div>`,
      })}
    />
  )
}

OrgAutocomplete.propTypes = {
  district: PropTypes.object,
  selections: PropTypes.array,
  update: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default OrgAutocomplete
