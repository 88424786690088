import { Select } from '@cb/apricot-react-forms'
import { useEffect, useMemo } from 'react'
import { QUERY_STATES } from '../../../../../services/graphql/queries'
import { useCustomQuery } from '../../../../../services/graphql/utils'
import { checkOrgAccess } from '../../../../../utils/auth'
import { ERROR } from '../../../../../utils/constants'
import Loader from '../../../../_common/loader/Loader'

const StateSelect = ({ state, update }) => {
  const { data, loading, error } = useCustomQuery(QUERY_STATES)
  const allowedStates = useMemo(
    () =>
      data?.reduce(
        (arr, s) => (checkOrgAccess(s.orgId, s.regionOrgId) ? [...arr, { label: s.stateName, value: s.orgId }] : arr),
        []
      ),
    [data]
  )

  useEffect(() => {
    if (allowedStates?.length === 1 && state?.value !== allowedStates[0]?.value) update({ state: allowedStates[0] })
  }, [allowedStates, state, update])

  return (
    <Loader loaded={Boolean(data)} loading={loading} spinnerSize="sm" error={error ? ERROR.DEFAULT : undefined}>
      <Select
        id="state-dropdown"
        label="State"
        labelNoValue={state ? undefined : 'Select'}
        values={allowedStates}
        value={state?.value}
        onChange={(val) => update({ state: allowedStates.find((a) => a.value === val) })}
        required
        floating
      />
    </Loader>
  )
}

StateSelect.propTypes = {
  state: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  update: PropTypes.func.isRequired,
}

export default StateSelect
