import { Select } from '@cb/apricot-react-forms'
import { updateCriteria } from '../../../../../services/session/findstudents'

const SelectAssessment = ({ assessments = [], selectedAssessment }) => (
  <Select
    id="test-administration-dropdown"
    label="Test Administration"
    values={assessments}
    value={selectedAssessment?.value}
    labelNoValue={selectedAssessment ? undefined : 'Select'}
    name="assessment"
    required
    validation="general"
    floating
    validationMsg="Only PSAT/SAT administrations where students at your organization have taken the test will be listed."
    onChange={(value) => updateCriteria({ assessment: assessments.find((a) => a.value === value) })}
  />
)

SelectAssessment.propTypes = {
  selectedAssessment: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  assessments: PropTypes.arrayOf(PropTypes.object),
}

export default SelectAssessment
