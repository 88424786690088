import { NakedButton, PrimaryButton } from '@cb/apricot-react-button'
import { Checkbox, CheckboxGroup } from '@cb/apricot-react-forms'
import { Modal } from '@cb/apricot-react-modal'
import isEqual from 'lodash/isEqual'
import { difference, eqProps, uniqWith } from 'ramda'
import { useEffect } from 'react'
import { QUERY_EDLEVEL_COUNTS } from '../../../../../services/graphql/queries'
import { useLookupLazyQuery } from '../../../../../services/graphql/utils'
import { getExamOrgVariables, updateCriteria } from '../../../../../services/session/findstudents'
import { DEFAULT_ALL } from '../../../../../utils/constants'
import { normalizeString } from '../../../../../utils/string'

// AP Precalculus, Art History, Computer Science Principles, European History, Human Geography, World History: Modern, Seminar, ref: #5 from https://collegeboard.atlassian.net/wiki/spaces/APP1/pages/67634170/1.1+APP+Navigation+by+User+Roles#id-1.1APPNavigation,byUserRoles-MainSuccessScenario5-SchoolUserwithaccesstoasingleschool
const INCLUDE9TH_CORRELATION_EXAMS = ['117', '2', '103', '15', '18', '34', '94']
const CORRELATION_GRADES = ['5', '6']
const CORRELATION_GRADES_W9TH = ['4', ...CORRELATION_GRADES]

const is9to11Grade = (grade) => new RegExp('9th|10th|11th', 'g').test(grade)

const SelectGrades = ({ criteria, activeEdLevels = [] }) => {
  const { edlevels, exams } = criteria
  // selections is the previous selections
  const { selections = [], all = false, checked9to11Grade = false } = edlevels || {}
  const { data = {}, fetch } = useLookupLazyQuery(QUERY_EDLEVEL_COUNTS, ['educationLevelCd', 'total'])
  const [open, setOpen] = useState(false)
  const examsSelected = exams?.length
  const correlationGrades = exams?.every((e) => INCLUDE9TH_CORRELATION_EXAMS.includes(e.value))
    ? CORRELATION_GRADES_W9TH
    : CORRELATION_GRADES
  const totalTestTakers = Object.values(data).reduce((sum, d) => sum + d, 0)

  const calculateSum = (selections) => selections?.reduce((sum, s) => sum + (data[s.value] || 0), 0)

  const active9to11Grades = activeEdLevels.filter(({ label, value }) => is9to11Grade(label) && data[value])

  const totalTestTakersFor9to11Grades = active9to11Grades.reduce((sum, { value }) => sum + data[value], 0)

  const is9to11GradesSeleted = (selections) => {
    // use to toggle 9th - 11th Grades checkbox
    const selected9to11Grades = selections.filter(({ label }) => is9to11Grade(label))
    return !Boolean(difference(active9to11Grades, selected9to11Grades).length)
  }

  const showAcknowledgement = (selections) => selections.find((s) => !correlationGrades.includes(s.value))

  const updateSelectedGrades = (selections, all = false, checked9to11Grade = false) =>
    updateCriteria({ edlevels: { selections, all, checked9to11Grade } })

  const getOnlyGradesWithTestTakers = (data = {}, levels) =>
    levels.reduce((arr, a) => (data[a.value] ? [...arr, a] : arr), [])

  useEffect(() => {
    // fetch latest data when assessment changed
    if (criteria.assessment) fetch({ variables: getExamOrgVariables() })
  }, [criteria.assessment, fetch])

  useEffect(() => {
    // this effect uncheck the grade levels that without test takers
    const availableGrades = getOnlyGradesWithTestTakers(data, activeEdLevels)
    const availableSelections = selections.filter((s) => availableGrades.find((g) => g.value === s.value))
    if (Object.keys(data).length && !isEqual(availableSelections, selections)) updateSelectedGrades(availableSelections)
  }, [data, selections, activeEdLevels])

  return (
    <>
      <div className="cb-checkbox-group">
        <CheckboxGroup
          legend={
            <>
              Grade Levels
              <sup className="cb-font-size-xsmall cb-margin-left-4">2</sup>
            </>
          }
          required
        >
          {[
            <Checkbox
              id="grade-levels-all"
              key="all"
              value={DEFAULT_ALL}
              label={`All Grades (${totalTestTakers} total test-takers)`}
              checked={all || (totalTestTakers && totalTestTakers === calculateSum(selections))}
              disabled={!examsSelected || !totalTestTakers}
              onChange={(checked) => {
                const availableGrades = getOnlyGradesWithTestTakers(data, activeEdLevels)
                const proposedSum = calculateSum(availableGrades)
                if (checked && (totalTestTakers !== proposedSum || showAcknowledgement(availableGrades))) setOpen(true)
                updateSelectedGrades(checked ? availableGrades : [], checked, checked)
              }}
            />,
            <Checkbox
              id="grade-levels-9-to-11"
              key="9to11Grades"
              value={'9-11'}
              label={`9th - 11th Grades (${totalTestTakersFor9to11Grades} total test-takers)`}
              checked={checked9to11Grade || (totalTestTakersFor9to11Grades && is9to11GradesSeleted(selections))}
              disabled={!examsSelected || !totalTestTakersFor9to11Grades}
              onChange={(checked) => {
                const differenceOf9to11Grades = difference(selections, active9to11Grades)
                const updates = checked
                  ? uniqWith(eqProps('value'), [...selections, ...active9to11Grades])
                  : differenceOf9to11Grades
                if (checked && showAcknowledgement(updates)) setOpen(true)
                updateSelectedGrades(checked ? updates : differenceOf9to11Grades, false, checked)
              }}
            />,
            ...activeEdLevels.map((e) => {
              const count = data[e.value] || 0
              return (
                <Checkbox
                  id={normalizeString(`grade-levels-${e.label}`)}
                  key={e.value}
                  value={e.value}
                  label={`${e.label} (${count} total test-takers)`}
                  disabled={!examsSelected || !count}
                  checked={Boolean((selections.find((s) => s.value === e.value) || all) && count)}
                  onChange={(checked) => {
                    const updates = checked ? [...selections, e] : selections.filter((s) => s.value !== e.value)
                    if (checked && showAcknowledgement(updates)) setOpen(true)
                    updateSelectedGrades(updates)
                  }}
                />
              )
            }),
          ]}
        </CheckboxGroup>
        <Modal
          analytics
          open={open}
          title="About Test-taker Populations"
          showHeaderCloseGlyph={false}
          footer={
            <>
              <NakedButton
                id="grade-level-modal-cancel"
                onClick={() => {
                  setOpen(false)
                  updateSelectedGrades(selections.filter((s) => correlationGrades.find((g) => g === s.value)))
                }}
              >
                Do Not Select Test-Takers
              </NakedButton>
              <PrimaryButton id="grade-level-modal-continue" onClick={() => setOpen(false)}>
                Continue with Selection
              </PrimaryButton>
            </>
          }
        >
          You have chosen a test-taker population that is not included in the statistical calculations of AP Potential
          for one or more subjects that you've selected. To view a list of students in these grades, you must first
          acknowledge that including these students in your AP Potential rosters is generally not recommended. If you
          choose to use these data as a preliminary, first-step in identifying potential AP students, be sure to combine
          this information with other indicators of potential from your school or district.
        </Modal>
      </div>
    </>
  )
}

SelectGrades.propTypes = {
  criteria: PropTypes.object.isRequired,
  activeEdLevels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

export default SelectGrades
