import { gql } from '@apollo/client'

export const MUTATION_SAVE_SETTINGS = gql`
  mutation saveSettings($settings: AWSJSON!) {
    saveSettings(settings: $settings)
  }
`

export const MUTATION_SAVE_ASSESSMENTS = gql`
  mutation saveAssessmentEvents($assessments: [AssessmentEventInput]!) {
    saveAssessmentEvents(assessments: $assessments)
  }
`
