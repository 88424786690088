import { parseDateTime } from 'app-utils/dist/dateTime/dateTime' // import as commonjs module, jest and babel can't handle es6 module

// treats all the time as UTC and not to perform any offset conversion
const zone = 'UTC'

// ref: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
export const FORMATS = {
  shortdate: 'LL/dd/yyyy', // 05/29/1900
  mediumdate: 'MMM d, yyyy', // Oct 1, 2023
  standarddate: 'yyyy-MM-dd', // 2023-10-01
  year: 'yyyy', // 2023
  month: 'MMMM', // October
}

export const formatDate = (d, formatter = FORMATS.shortdate) =>
  d ? parseDateTime(d, 'iso', null, { zone }, false).toFormat(formatter) : null

export const getEducationPeriodDescr = (code) => {
  const num = Number(code)
  return `20${num - 2}-20${num - 1}`
}
