import { RadioButton, RadioButtonGroup } from '@cb/apricot-react-forms'
import { SpacerV } from '@cb/apricot-react-spacer'
import { useEffect } from 'react'
import { QUERY_DOWNLOAD_CSV } from '../../../../services/graphql/queries'
import { getSearchVariables } from '../../../../services/session/findstudents'
import { DOWNLOADS } from '../../../../utils/analytics'
import FormIntro from '../../../_common/forms/formintro/FormIntro'
import DownloadButtons from '../_common/DownloadButtons'
import OrgAndSubjectSelections from '../_common/OrgAndSubjectSelections'
import DownloadRosterButton from './DownloadRosterButton'

const OPTION_STATE = 'state'
const OPTION_ORG = 'org'

const CSVForm = ({ exams = [], availableOrgs = [], state, data = [], pools, assessment, closeModal }) => {
  const [processing, setProcessing] = useState(false)
  const [selected, setSelected] = useState({ exams, orgs: availableOrgs })
  const [stateSelectionType, setStateSelectionType] = useState(state ? OPTION_STATE : OPTION_ORG)
  const isSingleOrgSelected = availableOrgs.length === 1
  const stateSelected = stateSelectionType === OPTION_STATE
  const variables =
    selected.exams?.length && (stateSelected || selected.orgs?.length)
      ? getSearchVariables(
          stateSelected ? undefined : selected.orgs.map((o) => o.value),
          selected.exams.map((e) => e.value)
        )
      : undefined

  const onChange = (selectedOrgs, selectedExams) => setSelected({ exams: selectedExams, orgs: selectedOrgs })

  useEffect(() => {
    setSelected((curr) => ({
      exams: exams || curr.exams,
      orgs: availableOrgs || curr.orgs,
    }))
  }, [exams, availableOrgs])

  return (
    <>
      <FormIntro
        text={
          state
            ? 'Select "By State" to download data for all organizations within the selected state or "By Organizations" to choose a subset of those organizations.'
            : 'Select an organization and/or subject to download a specific dataset or download all students. By default, all subjects indicated during the initial roster creation are selected.'
        }
        required
      />
      {state ? (
        <>
          <RadioButtonGroup value={stateSelectionType} legend="Download Type" onChange={setStateSelectionType}>
            <RadioButton label="By State" value={OPTION_STATE} disabled={processing} />
            <RadioButton label="By Organization" value={OPTION_ORG} disabled={processing} />
          </RadioButtonGroup>
          <SpacerV size={24} />
        </>
      ) : null}
      <OrgAndSubjectSelections
        availableExams={exams}
        availableOrgs={availableOrgs}
        showOrgSelect={!state || stateSelectionType === OPTION_ORG}
        onChange={onChange}
        disabled={processing}
      />
      <DownloadButtons
        buttonId="download-csv-button"
        query={QUERY_DOWNLOAD_CSV}
        variables={variables}
        onProcessing={setProcessing}
        onCancel={closeModal}
        onDownload={closeModal}
        downloadName={DOWNLOADS.csv}
        altDownload={
          isSingleOrgSelected ? (
            <DownloadRosterButton
              data={data}
              org={availableOrgs[0]}
              pools={pools}
              assessment={assessment}
              exams={selected.exams}
              disabled={!selected.exams.length}
              onDownload={closeModal}
            />
          ) : undefined
        }
      />
    </>
  )
}

CSVForm.propTypes = {
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  availableOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  state: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  data: PropTypes.arrayOf(PropTypes.object),
  pools: PropTypes.object,
  assessment: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  closeModal: PropTypes.func.isRequired,
}

export default CSVForm
