import { getCBEnv, isInternal } from './auth'

let config = null

const PROD = 'pine'
const REGION = 'us-east-1'

const CB_ENV_MAP = {
  oak: 'dev',
  palm: 'qa',
  maple: 'oat',
  apple: 'uat',
  spruce: 'pt',
  [PROD]: 'prod',
}

// Config.LOCAL is populated by webpack
const isLocalOrDev = () => Config.LOCAL || window.location.host.split('.')[0] === 'dev'

// resolve api env based on proxy status or local development
// hard coded local development to use int-dev.appotential-nonprod.collegeboard.org/graphql
export default () => {
  if (!config) {
    const cbEnv = getCBEnv() || PROD // default to prod
    const appotentialEnv = isLocalOrDev() ? 'dev' : CB_ENV_MAP[cbEnv]
    const internal = isInternal()

    if (cbEnv === PROD)
      config = {
        cbUrl: 'https://organization.cds-prod.collegeboard.org/pine',
        cbApiKey: 'bCjAE1TxfW4hKsUviAckx6AU5yDMZQ5m29aCmhdH',
        appsyncUrl: internal
          ? 'https://int-prod.appotential-prod.collegeboard.org/graphql'
          : 'https://appotential-api.collegeboard.org/graphql',
        appsyncRegion: REGION,
      }
    else
      config = {
        cbUrl: `https://organization.cds-nonprod.collegeboard.org/spruce`,
        cbApiKey: 'FY2IO65gu57QRF1mj4Sr6CV2CAjCW578StWoEs2g',
        appsyncUrl: `https://${
          internal ? 'int' : 'service'
        }-${appotentialEnv}.appotential-nonprod.collegeboard.org/graphql`,
        appsyncRegion: REGION,
      }
  }

  return config
}
