import { Column, Table } from '@cb/apricot-react-table'
import orderBy from 'lodash/orderBy'
import { useEffect, useRef, useCallback } from 'react'
import Controls from './Controls'

const PagerTable = ({ data = [], columns, options = {}, label }) => {
  const tableRef = useRef(undefined)
  const totalStudents = data.length
  const [bookends, setBookends] = useState([0, 0])
  const [forceResize, setForceResize] = useState(true)

  const onChange = useCallback((start, end) => setBookends([start, end]), [])

  // make sure forceResize is updated with each data change to trigger apricot table resize event
  // this helps with frozen column/header getting out of alignment on rerender
  useEffect(() => {
    setForceResize(false)
  }, [data])

  useEffect(() => {
    if (!forceResize) setForceResize(true)
  }, [forceResize])

  useEffect(() => {
    if (bookends[1] > totalStudents) setBookends([0, totalStudents])
  }, [totalStudents, bookends])

  return (
    <>
      <Table
        {...options}
        forceResize={forceResize}
        data={data.slice(...bookends)}
        sortFn={(unused, { field, ascending }) => orderBy(data, field, ascending ? 'asc' : 'desc').slice(...bookends)}
      >
        {columns.map((c, i) => (
          <Column {...c} key={i} />
        ))}
      </Table>
      <Controls onChange={onChange} total={totalStudents} topPosition={tableRef?.current?.topPosition} label={label} />
    </>
  )
}

PagerTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  options: PropTypes.object,
  label: PropTypes.string.isRequired,
}

export default PagerTable
