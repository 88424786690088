import { useMutation } from '@apollo/client'
import { PrimaryButton } from '@cb/apricot-react-button'
import { SpacerV } from '@cb/apricot-react-spacer'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { MUTATION_SAVE_SETTINGS } from '../../../services/graphql/mutations'
import { QUERY_SETTINGS } from '../../../services/graphql/queries'
import { useLookupQuery } from '../../../services/graphql/utils'
import { ERROR } from '../../../utils/constants'
import Error from '../../_common/error/Error'
import Loader from '../../_common/loader/Loader'
import Toasts from '../../_common/toasts/Toasts'
import EdPeriods from './EdPeriods'
import Grades from './Grades'
import Subjects from './Subjects'

const Settings = () => {
  const [saves, updateSaves] = useState(0)
  const { loading, error, data } = useLookupQuery(QUERY_SETTINGS, ['code', 'active'])
  const [selections, setSelections] = useState({})
  const [update, { loading: saving, error: saveError }] = useMutation(MUTATION_SAVE_SETTINGS, {
    variables: {
      settings: JSON.stringify(
        Object.keys(selections).reduce(
          (arr, code) => (selections[code] ? [...arr, { code, active: selections[code] }] : arr),
          []
        )
      ),
    },
    refetchQueries: [QUERY_SETTINGS],
    onCompleted: () => updateSaves(saves + 1),
  })

  if (saveError) {
    console.log(saveError)
  }

  const updateSelections = (type) => (value, checked) => {
    const selected = selections[type]
    setSelections((current) => ({
      ...current,
      [type]:
        checked === undefined
          ? [value]
          : checked
            ? [...selected, value].sort((a, b) => Number(a) - Number(b))
            : selected.filter((e) => e !== value),
    }))
  }

  useEffect(() => {
    if (data) setSelections(data)
  }, [data])

  return (
    <Loader
      loaded={Boolean(data)}
      loading={loading}
      spinnerCenter
      error={error ? ERROR.ADMIN_LOAD_SETTINGS : undefined}
    >
      <div className="container">
        <h1 className="cb-h3 cb-roboto-light">Settings</h1>
        <SpacerV size={24} />
        <EdPeriods selected={selections.edPeriod && selections.edPeriod[0]} onChange={updateSelections('edPeriod')} />
        <SpacerV size={32} />
        <Subjects selected={selections.apAsmt} onChange={updateSelections('apAsmt')} />
        <SpacerV size={32} />
        <Grades selected={selections.edLevel} onChange={updateSelections('edLevel')} />
        <SpacerV size={32} />
        {saveError ? (
          <Error className="cb-margin-bottom-8" message={ERROR.ADMIN_SAVE_SETTINGS.message} container={false} />
        ) : null}
        <PrimaryButton
          onClick={update}
          loading={saving}
          disabled={
            isEqual(selections, data) ||
            selections.edLevel?.length === 0 ||
            selections.apAsmt?.length === 0 ||
            selections.edPeriods?.length === 0 ||
            saving
          }
        >
          Update Settings
        </PrimaryButton>
        <Toasts message="Settings successfully updated" saves={saves} />
      </div>
    </Loader>
  )
}

export default Settings
