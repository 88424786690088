import { Stepper as CBStepper, SimpleStep } from '@cb/apricot-react-stepper'

import './stepper.scss'

const Stepper = ({ steps, selectedIndex }) => (
  <div className="stepper-align-left">
    <CBStepper ariaLabel="Criteria selection stepper" horizontalFix simple>
      {steps.map((s, i) => (
        <SimpleStep key={i} state={selectedIndex === i ? 'active' : selectedIndex < i ? 'disabled' : 'complete'} />
      ))}
    </CBStepper>
  </div>
)

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex: PropTypes.number.isRequired,
}

export default Stepper
