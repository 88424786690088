import { Select } from '@cb/apricot-react-forms'
import { SpacerV } from '@cb/apricot-react-spacer'
import { useEffect } from 'react'
import {
  SEARCH_TYPE_AICODE,
  SEARCH_TYPE_NAME,
  SEARCH_TYPE_ORGID,
  SEARCH_TYPE_STATE,
} from '../../../../services/rest/orgsearch'
import { updateCriteria } from '../../../../services/session/findstudents'
import { getAllowedDistricts, isDistrict, isMultiOrgUser, isSchool } from '../../../../utils/auth'
import FormIntro from '../../../_common/forms/formintro/FormIntro'
import OrgAutocomplete from './fields/OrgAutocomplete'
import SchoolMultiselect from './fields/SchoolMultiselect'
import SchoolSelect from './fields/SchoolSelect'
import StateSelect from './fields/StateSelect'

const Organization = ({ criteria }) => {
  const { state, district, selections, type = SEARCH_TYPE_NAME } = criteria?.org || {}
  const isMultiOrg = isMultiOrgUser()
  const isSingleDistrict = isDistrict() && !isMultiOrg
  const isMultiSchool = isSchool() && isMultiOrg
  const showSearchType = !isSingleDistrict && !isMultiSchool

  const update = (values, reset) => {
    const updates = reset ? values : { ...criteria.org, ...values }
    updateCriteria({ org: updates })
  }

  const getAvailableSearchTypes = () => {
    const options = [
      { value: SEARCH_TYPE_NAME, label: 'Organization Name' },
      { value: SEARCH_TYPE_AICODE, label: 'School Code' },
      { value: SEARCH_TYPE_ORGID, label: 'District Code' },
    ]

    if (!isDistrict()) options.push({ value: SEARCH_TYPE_STATE, label: 'State' })

    return options
  }

  useEffect(() => {
    const districtOrgs = getAllowedDistricts()
    if (isSingleDistrict && district?.orgId !== districtOrgs[0])
      updateCriteria({ org: { district: { orgId: districtOrgs[0] } }, type: SEARCH_TYPE_ORGID })
  }, [isSingleDistrict, district])

  return (
    <fieldset>
      <FormIntro
        title="Organization"
        text={`Please select the school(s) for which you want to see student potential
        ${!isDistrict() ? ' or select a state to see an aggregated report' : ''}. You must select at least one school.`}
        required
      />
      {showSearchType ? (
        <>
          <Select
            id="search-type-dropdown"
            label="Search Type"
            values={getAvailableSearchTypes()}
            value={type}
            onChange={(value) => update({ type: value }, true)}
            required
            floating
          />
          <SpacerV size={24} />
          {type === SEARCH_TYPE_STATE ? (
            <StateSelect update={update} state={state} />
          ) : (
            <OrgAutocomplete update={update} type={type} district={district} selections={selections} />
          )}
        </>
      ) : null}
      {district || isSingleDistrict ? (
        <>
          {isSingleDistrict ? null : <SpacerV size={24} />}
          <SchoolMultiselect selections={selections} district={district} update={update} />
        </>
      ) : null}
      {isMultiSchool ? <SchoolSelect selections={selections} update={update} /> : null}
    </fieldset>
  )
}

Organization.propTypes = {
  criteria: PropTypes.object,
}

export default Organization
