import { Icon } from '@cb/apricot-react'
import { QUERY_AP_ASSESSMENTS, QUERY_EDLEVELS } from '../../../services/graphql/queries'
import { useLookupQuery } from '../../../services/graphql/utils'
import PagerTable from '../../_common/pagertable/PagerTable'
import StudentColumn from './columns/StudentColumn'

const SnapshotTable = ({ data = [], exams, pools }) => {
  const { data: edlevels = {} } = useLookupQuery(QUERY_EDLEVELS, ['code', 'gradeName'])
  const { data: subjects = {} } = useLookupQuery(QUERY_AP_ASSESSMENTS, ['apAsmtId', 'asmtAbbr'])

  return (
    <PagerTable
      label="Student"
      data={data}
      options={{
        stickyColumn: true,
        stickyHeader: true,
        responsive: true,
        condensed: true,
        striped: true,
        initialSort: { field: 'lastName', ascending: true },
      }}
      columns={[
        {
          title: 'Name',
          field: 'lastName',
          sortable: true,
          component: ({ row }) => <StudentColumn {...row} />,
          sticky: true,
          rowHeader: true,
        },
        {
          title: 'Grade',
          field: 'educationLevelCd',
          sortable: true,
          component: ({ value }) => edlevels[value] || '',
        },
        ...exams.map((s) => ({
          title: `${subjects[s.value] || ''}|Pool: ${pools[s.value]}`,
          field: s.value,
          component: ({ row }) =>
            row.evaluatedCutScores[s.value] ? (
              <div className="cb-align-center">
                <Icon name="check" decorative />
                <span className="sr-only">Has Potential</span>
              </div>
            ) : null,
        })),
      ]}
    />
  )
}

SnapshotTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  pools: PropTypes.object.isRequired,
}

export default SnapshotTable
