import { SpacerV } from '@cb/apricot-react-spacer'
import Header from '../../header/Header'

const FormIntro = ({ required, title, text }) => (
  <>
    {title ? (
      <legend>
        <Header title={title} id="form-intro-title" />
      </legend>
    ) : null}
    {text ? (
      <p className="cb-font-size-small" id="form-intro-text">
        {text}
      </p>
    ) : null}
    {required ? <p className="cb-font-size-small">* indicates a required field</p> : null}
    <SpacerV size={24} />
  </>
)

FormIntro.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  text: PropTypes.string,
}

export default FormIntro
