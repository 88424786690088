import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import getEnvConfig from '../../utils/environment'
import { getAuthorizationToken } from '../../utils/auth'
import typePolicies from './policies'

let client = null
export const createCache = () => new InMemoryCache({ typePolicies })

export const appsyncClient = () => {
  const { appsyncUrl: url, appsyncRegion: region } = getEnvConfig()
  const auth = { type: AUTH_TYPE.AWS_LAMBDA, token: () => `cbtoken-${getAuthorizationToken()}` }

  if (!client)
    client = new ApolloClient({
      link: ApolloLink.from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink({ url, region, auth }, createHttpLink({ uri: url })),
      ]),
      cache: createCache(),
    })

  return client
}
