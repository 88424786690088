import { Icon } from '@cb/apricot-react'
import { NakedButton, PrimaryButton } from '@cb/apricot-react-button'
import { useCallback, useEffect } from 'react'
import { useCustomLazyQuery } from '../../../../services/graphql/utils'
import { trackDownload } from '../../../../utils/analytics'
import { getAuthSession } from '../../../../utils/auth'
import DownloadProgress from '../_common/DownloadProgress'

const DownloadButtons = ({
  variables,
  title = 'Download',
  buttonId = 'download',
  query,
  onDownload,
  onCancel,
  onProcessing,
  downloadName,
  altDownload: DownloadButton,
}) => {
  const [downloadId, setDownloadId] = useState(undefined)
  const { fetch, error } = useCustomLazyQuery(query)
  const [downloadError, setDownloadError] = useState(undefined)

  const onComplete = useCallback(
    (error) => {
      setDownloadId(undefined)
      if (error) setDownloadError(error)
      else if (onDownload) onDownload()
    },
    [onDownload]
  )

  useEffect(() => {
    setDownloadError(error)
    setDownloadId(undefined)
  }, [error])

  return (
    <div className="cb-margin-top-24">
      {downloadId ? <DownloadProgress downloadId={downloadId} onComplete={onComplete} /> : null}
      {downloadError ? (
        <div className="cb-margin-bottom-8">
          <div className="display-flex align-items-center cb-red1-color">
            <Icon color="red1" name="error" decorative className="cb-margin-right-4" />
            <div className="cb-font-weight-medium">
              Error: An error occurred downloading the file. Please try again.
            </div>
          </div>
        </div>
      ) : null}
      <div className="cb-align-right">
        {onCancel ? (
          <NakedButton
            id="cancel"
            onClick={(e) => {
              setDownloadId(undefined)
              onCancel(e)
            }}
            className="cb-margin-right-4"
          >
            {downloadId ? `I'd like to cancel my download` : 'Cancel'}
          </NakedButton>
        ) : null}
        {DownloadButton || (
          <PrimaryButton
            id={buttonId}
            disabled={!variables || downloadId}
            onClick={() => {
              const downloadId = `${new Date().toISOString()}-${getAuthSession().basicProfile?.hashedId}`
              setDownloadId(downloadId)
              setDownloadError(undefined)
              onProcessing(true)
              trackDownload(downloadName)
              fetch({ variables: { ...variables, downloadId } })
            }}
            icon="download"
            iconDecorative
          >
            {title}
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

DownloadButtons.propTypes = {
  variables: PropTypes.object,
  title: PropTypes.string,
  buttonId: PropTypes.string,
  query: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onDownload: PropTypes.func,
  onProcessing: PropTypes.func,
  altDownload: PropTypes.object,
  downloadName: PropTypes.string.isRequired,
}

export default DownloadButtons
