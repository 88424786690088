import classnames from 'classnames'
import './actionbar.scss'

const ActionBar = ({ children, widthClass = 'col-xs-12' }) => {
  const kidCount = Array.isArray(children) ? children.filter((c) => c).length : 1
  const classes = kidCount > 1 ? 'display-flex justify-content-between align-items-center' : 'cb-align-right'
  return (
    <div className="appotential-actionbar cb-box-shadow-top" role="region" aria-label="action bar">
      <div className="container">
        <div className="row justify-content-between">
          <div className={classnames(widthClass, classes)}>{children}</div>
        </div>
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  widthClass: PropTypes.string,
}

export default ActionBar
