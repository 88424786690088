import orderBy from 'lodash/orderBy'
import { criteriaVar } from '../session/findstudents'

const parser = (d) => (d ? JSON.parse(d) : {})

export default {
  Query: {
    fields: {
      getSearchCriteria: {
        read() {
          return criteriaVar()
        },
      },
      getDistrictOrgs: {
        merge(existing, incoming = []) {
          return orderBy(incoming, 'orgName', 'asc')
        },
      },
      getStateOrgs: {
        merge(existing, incoming = []) {
          return orderBy(incoming, 'orgName', 'asc')
        },
      },
    },
  },
  Student: {
    fields: {
      scaledScores(value) {
        return parser(value)
      },
      evaluatedCutScores(value) {
        return parser(value)
      },
      assessments(value) {
        return value
      },
    },
  },
  AssessmentResult: {
    fields: {
      scaledScores(value) {
        return parser(value)
      },
    },
  },
  EducationPeriod: {
    fields: {
      nextDescr(value, { readField }) {
        return readField('descr')
          .split('-')
          .map((year) => parseInt(year, 10) + 1)
          .join('-')
      },
      prevDescr(value, { readField }) {
        return readField('descr')
          .split('-')
          .map((year) => parseInt(year, 10) - 1)
          .join('-')
      },
      prevCode(value, { readField }) {
        return readField('code') - 1
      },
    },
  },
  CutScoreCounts: {
    fields: {
      highestCutScoreByOrg(value) {
        return parser(value)
      },
    },
  },
}
