import { Select } from '@cb/apricot-react-forms'
import { useEffect } from 'react'
import { QUERY_ORG } from '../../../../../services/graphql/queries'
import { formatResults, useCustomLazyQuery } from '../../../../../services/graphql/utils'
import { getAllowedSchools } from '../../../../../utils/auth'
import { ERROR } from '../../../../../utils/constants'
import Loader from '../../../../_common/loader/Loader'

const SchoolSelect = ({ selections = [], update }) => {
  const [options, setOptions] = useState([])
  const { fetch, error } = useCustomLazyQuery(QUERY_ORG)
  const selected = selections[0]?.value

  useEffect(() => {
    const getSchools = async () => {
      const schools = getAllowedSchools()
      // need to execute sequentially or else variables will all be the same
      const results = await schools.reduce(async (prevPromise, org) => {
        const prev = await prevPromise
        const { data } = await fetch({ variables: { org } })
        return [...prev, formatResults(data, (d) => ({ value: d.orgId, label: d.orgName }))]
      }, Promise.resolve([]))
      setOptions(results)
    }
    getSchools()
  }, [fetch])

  return (
    <Loader
      loaded={Boolean(options.length)}
      loading={!options.length}
      spinnerSize="sm"
      error={error ? ERROR.DEFAULT : undefined}
    >
      <Select
        id="school-dropdown"
        label="Schools"
        labelNoValue={selected ? undefined : 'Select'}
        values={options}
        value={selected}
        onChange={(val) => update({ selections: options.filter((a) => a.value === val) })}
        required
        floating
      />
    </Loader>
  )
}

SchoolSelect.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  update: PropTypes.func.isRequired,
}

export default SchoolSelect
