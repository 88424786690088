import { SpacerV } from '@cb/apricot-react-spacer'
import orderBy from 'lodash/orderBy'
import {
  QUERY_AP_ASSESSMENTS,
  QUERY_ASSESSMENTS,
  QUERY_EDLEVELS,
  QUERY_SETTINGS,
} from '../../../../services/graphql/queries'
import { useCustomQuery, useLookupQuery } from '../../../../services/graphql/utils'
import { ERROR } from '../../../../utils/constants'
import FormIntro from '../../../_common/forms/formintro/FormIntro'
import Loader from '../../../_common/loader/Loader'
import Disclaimers from './Disclaimers'
import SelectAssessment from './fields/SelectAssessment'
import SelectExams from './fields/SelectExams'
import SelectGrades from './fields/SelectGrades'

const TestCriteria = ({ criteria }) => {
  const assessments = useCustomQuery(QUERY_ASSESSMENTS)
  const edlevels = useLookupQuery(QUERY_EDLEVELS, ['code', 'gradeName'])
  const exams = useLookupQuery(QUERY_AP_ASSESSMENTS, ['apAsmtId', 'asmtName'])
  const settings = useLookupQuery(QUERY_SETTINGS, ['code', 'active'])
  const { edLevel: activeEdLevels = [], apAsmt: activeApAsmts = [], edPeriod = [] } = settings.data || {}
  const loading = assessments.loading || edlevels.loading || exams.loading || settings.loading
  const error = assessments.error || edlevels.error || exams.error || settings.error
  const loaded = Boolean(assessments.data && edlevels.data && exams.data && settings.data)

  return (
    <Loader loaded={loaded} loading={loading} spinnerCenter error={error ? ERROR.DEFAULT : undefined}>
      <fieldset>
        <FormIntro
          title="Test Criteria"
          text="Use the following options to refine the criteria you want to use to calculate students' potential."
          required
        />
        <SelectAssessment
          selectedAssessment={criteria.assessment}
          assessments={assessments.data?.map((a) => ({
            value: a.assessment,
            label: a.eventName,
          }))}
        />
        <SpacerV size={32} />
        <SelectExams
          criteria={criteria}
          educationPeriodCd={Number(edPeriod[0]) - 1}
          activeApAsmts={
            exams.data
              ? orderBy(
                  activeApAsmts.map((a) => ({ value: a, label: exams.data[a] })),
                  'label',
                  'asc'
                )
              : []
          }
        />
        <SpacerV size={32} />
        <SelectGrades
          criteria={criteria}
          activeEdLevels={
            edlevels.data
              ? activeEdLevels.map((a) => ({
                  value: a,
                  label: edlevels.data[a],
                }))
              : []
          }
        />
      </fieldset>
      <Disclaimers />
    </Loader>
  )
}

TestCriteria.propTypes = {
  criteria: PropTypes.object,
}

export default TestCriteria
