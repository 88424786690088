import { Select } from '@cb/apricot-react-forms'
import { Column, Table } from '@cb/apricot-react-table'
import orderBy from 'lodash/orderBy'
import { useEffect } from 'react'
import { QUERY_AP_ASSESSMENTS, QUERY_CUTSCORES } from '../../../services/graphql/queries'
import { useCustomQuery, useLookupQuery } from '../../../services/graphql/utils'
import { ERROR } from '../../../utils/constants'
import Loader from '../../_common/loader/Loader'

const CutScores = () => {
  const [availableExams, setAvailableExams] = useState([])
  const [selected, setSelected] = useState(undefined)
  const cuts = useLookupQuery(QUERY_CUTSCORES, ['apAsmtId'])
  const exams = useCustomQuery(QUERY_AP_ASSESSMENTS)
  const loaded = Boolean(cuts.data && exams.data)
  const error = cuts.error || exams.error

  useEffect(() => {
    if (cuts.data && exams.data) {
      const available = orderBy(
        exams.data.reduce(
          (arr, d) => (cuts.data[d.apAsmtId] ? [...arr, { label: d.asmtName, value: d.apAsmtId }] : arr),
          []
        ),
        'label',
        'asc'
      )

      setAvailableExams(available)
      setSelected(available[0].value)
    }
  }, [cuts.data, exams.data])

  return (
    <Loader
      loaded={loaded}
      loading={cuts.loading || exams.loading}
      spinnerCenter
      error={error ? ERROR.DEFAULT : undefined}
    >
      <div className="container">
        <h1 className="cb-h3 cb-roboto-light cb-margin-bottom-24">Cut Scores</h1>
        <div className="row cb-margin-bottom-24">
          <div className="col-sm-6 col-md-4">
            <Select
              values={availableExams}
              label="AP Subjects"
              name="apsubject"
              validation="general"
              floating
              onChange={setSelected}
            />
          </div>
        </div>
        <Table
          data={cuts.data ? cuts.data[selected]?.cutScores : []}
          initialSort={{ field: 'potentialPercentage', ascending: true }}
          striped
        >
          <Column field="potentialPercentage" sortable />
          <Column field="minScore" />
          <Column field="estimatedPotentialCd" />
          <Column field="estimatedPotentialDescr" />
        </Table>
      </div>
    </Loader>
  )
}

export default CutScores
