import { Tooltip } from '@cb/apricot-react-tooltip'
import { CircularButton } from '@cb/apricot-react-button'
import { useLookupQuery } from '../../../services/graphql/utils'
import { QUERY_ETHNICITIES, QUERY_GENDERS, QUERY_EDLEVELS, QUERY_TIERS } from '../../../services/graphql/queries'
import StudentColumn from './columns/StudentColumn'
import PagerTable from '../../_common/pagertable/PagerTable'

const SubjectTable = ({ data, subject }) => {
  const ethnicity = useLookupQuery(QUERY_ETHNICITIES, ['code', 'ethnicityName'])
  const gender = useLookupQuery(QUERY_GENDERS, ['code', 'genderName'])
  const edlevels = useLookupQuery(QUERY_EDLEVELS, ['code', 'gradeName'])
  const tiers = useLookupQuery(QUERY_TIERS, ['apAsmtId'])
  const { asmtScoreTierAbbr, asmtCorrelation, description } = (tiers.data && tiers.data[subject]) || {}

  return (
    <PagerTable
      label="Student"
      data={data}
      options={{
        stickyHeader: true,
        stickyColumn: true,
        responsive: true,
        condensed: true,
        striped: true,
        initialSort: { field: 'lastName', ascending: true },
      }}
      columns={[
        {
          title: 'Name',
          field: 'lastName',
          sortable: true,
          sticky: true,
          component: ({ row }) => <StudentColumn {...row} />,
        },
        {
          field: 'educationLevelCd',
          title: 'Grade',
          sortable: true,
          component: ({ value }) => (edlevels.data && edlevels.data[value]) || '',
        },
        {
          field: 'genderCd',
          title: 'Gender',
          sortable: true,
          component: ({ value }) => (gender.data && gender.data[value]) || '',
        },
        {
          field: 'ethnicityCd',
          title: 'Ethnicity',
          sortable: true,
          component: ({ value }) => (ethnicity.data && ethnicity.data[value]) || '',
        },
        {
          field: 'scaledScores',
          className: 'cb-align-right',
          headerComponent: () => (
            <>
              {description} <CircularButton icon="question" id="tableHeaderTooltip" small light />
              <Tooltip trigger="tableHeaderTooltip" triggerEvent="hover focus click">
                <p>Correlation Value: {asmtCorrelation}</p>
                <div className="cb-roboto cb-margin-top-8">
                  The correlation value represents the strength of relationship between SAT Suite of Assessment Scores
                  and AP exam scores in a specific subject.
                </div>
              </Tooltip>
            </>
          ),
          component: ({ value }) => value[asmtScoreTierAbbr] || '',
        },
        {
          field: 'evaluatedCutScores',
          className: 'cb-align-center',
          title: 'Students with Same Score Who Received AP Score of 3+',
          component: ({ value }) => (value[subject] ? `${value[subject]}%` : ''),
        },
      ]}
    />
  )
}

SubjectTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  pools: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
}

export default SubjectTable
