import { makeVar } from '@apollo/client'
import { getAuthSession } from '../../utils/auth'
import { APP_LINKS } from '../../utils/navigation'
import { getSessionJSON, removeSessionItem, setSessionJSON } from '../../utils/storage'

export const CRITERIA_STORAGE_KEY = 'appotential-criteria'

export const criteriaVar = makeVar({})

export const setCriteria = () => {
  const { sessionId } = getAuthSession()
  const criteriaFromStorage = getSessionJSON(CRITERIA_STORAGE_KEY)
  const useExisting = criteriaFromStorage.sessionId === sessionId
  if (useExisting) criteriaVar(criteriaFromStorage)
  else {
    const update = { sessionId, k12SelectedSchoolOrgId: criteriaFromStorage.k12SelectedSchoolOrgId }
    criteriaVar(update)
    setSessionJSON(CRITERIA_STORAGE_KEY, update)
  }
}

export const resetCriteria = () => {
  const { k12SelectedSchoolOrgId, org, sessionId } = criteriaVar()
  criteriaVar({})
  removeSessionItem(CRITERIA_STORAGE_KEY)
  // if schoolOrgId is passed in, update the criteria
  if (k12SelectedSchoolOrgId) {
    updateCriteria({
      sessionId, // keep the sessionId and it will force setCriteria to use the existing criteria
      k12SelectedSchoolOrgId,
      org,
    })
  }
}

export const updateCriteria = (updates) => {
  const criteria = criteriaVar()
  if (criteria) {
    const updated = { ...criteria, ...updates }
    criteriaVar(updated)
    setSessionJSON(CRITERIA_STORAGE_KEY, updated)
  } else console.warn('Skipping update because saved criteria has not loaded yet!')
}

export const getK12SelectedSchoolOrgId = () => {
  const { k12SelectedSchoolOrgId } = criteriaVar() || {}
  return k12SelectedSchoolOrgId
}

export const getExamOrgVariables = (includeExams) => {
  const { org = {}, assessment, exams } = criteriaVar()
  const { state, selections } = org
  const orgs = selections?.length ? selections.map((s) => s.value) : undefined
  const apAsmts = includeExams && exams?.length ? exams.map((e) => e.value) : undefined
  return { state: state?.value, orgs, assessment: assessment?.value, apAsmts }
}

export const getSearchVariables = (selectedOrgs, selectedExams) => {
  const { org = {}, edlevels = {}, assessment, pools = {} } = criteriaVar() || {}
  const { selections, state } = org
  const exams = selectedExams || Object.keys(pools)
  const orgs = selectedOrgs
    ? { orgs: selectedOrgs }
    : state
      ? { state: state.value }
      : { orgs: selections.map((s) => s.value) }

  return {
    ...orgs,
    assessment: assessment?.value,
    edlevels: edlevels.all ? undefined : edlevels.selections.map((s) => s.value),
    pools: JSON.stringify(exams.reduce((obj, apAsmtId) => ({ ...obj, [apAsmtId]: pools[apAsmtId] }), {})),
  }
}

export const getStudentVariables = (catapultId) => {
  const { assessment, pools = {} } = criteriaVar() || {}
  return {
    catapultId,
    assessment: assessment?.value,
    pools: JSON.stringify(pools),
  }
}

export const isValid = (path) => {
  const { org = {}, edlevels = {}, assessment, exams = [], pools } = criteriaVar() || {}
  const orgValid = org.selections?.length || org.state
  const criteriaValid = (edlevels.selections?.length || edlevels.all) && assessment && exams.length

  switch (path) {
    case APP_LINKS.organization:
      return orgValid
    case APP_LINKS.studentpool:
    case APP_LINKS.testcriteria:
      return orgValid && criteriaValid
    default:
      return orgValid && criteriaValid && pools
  }
}

export const getAnalyticsData = () => {
  const { org = {}, edlevels = {}, assessment, pools = {} } = criteriaVar() || {}
  const { district, selections, state } = org
  const hasMultiSelections = selections?.length > 1
  const grades = edlevels.selections?.map((s) => s.label)
  const selectedPoolPcts = [...new Set(Object.values(pools))]

  return {
    orgType: hasMultiSelections ? 'district' : state ? 'state' : 'school',
    orgName: state?.label || district?.orgId || selections?.map((s) => s.label).join('|'),
    schoolsSelected: state || district?.all ? 'all' : hasMultiSelections ? 'some' : 'one',
    admins: assessment?.label,
    gradeLevels: edlevels.all ? ['All Grades', ...grades] : grades,
    studentPools: selectedPoolPcts.map((s) => ({ [s]: Object.keys(pools).filter((p) => pools[p] === s) })),
  }
}
