import { gql } from '@apollo/client'

export const SUBSCRIPTION_DOWNLOAD = gql`
  subscription downloadUpdate($downloadId: String!) {
    downloadUpdate(downloadId: $downloadId) {
      downloadId
      url
      message
      step
    }
  }
`
