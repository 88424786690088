import { Icon } from '@cb/apricot-react'
import { InputDropdown } from '@cb/apricot-react-forms'
import CBUtils from '@cb/apricot/CBUtils'
import { useEffect, useRef, useState } from 'react'

const event = 'autocomplete.data.loaded'

const Autocomplete = ({
  disabled,
  fieldName,
  onSelection,
  onBuild,
  label,
  required,
  errMsg,
  helperMsg,
  fetch,
  defaultValue,
  value,
  isValid,
  labelClassName = '',
}) => {
  const autocompleteRef = useRef(null)
  const [visited, setVisited] = useState(false)
  const [valid, setValid] = useState('general')
  const [inputValue, setInputValue] = useState(value ?? defaultValue ?? '')

  const hasError = valid === 'error'

  const triggerEvent = (data) => {
    const customEvent = new CustomEvent(event)
    customEvent.data = data
    autocompleteRef.current.dispatchEvent(customEvent)
  }

  const updateOptions = async (e) => {
    setInputValue(e.target.value)
    const data = await fetch(e.target.value)
    triggerEvent(data)
  }

  useEffect(() => {
    setInputValue(defaultValue || value)
  }, [defaultValue, value])

  useEffect(() => {
    setValid(isValid || !visited ? 'general' : 'error')
  }, [isValid, visited])

  return (
    <>
      <div className={`cb-validation-${valid}`}>
        <div className="cb-validation-label-input">
          <InputDropdown
            labelId={`${fieldName}-input-dropdown-label`}
            labelClassName={labelClassName}
            inputId={`${fieldName}-input-dropdown`}
            dropdownId={`${fieldName}-input-dropdown`}
            ref={autocompleteRef}
            // defaultValue={defaultValue} // prefer to use value as controlled component
            value={inputValue ?? ''} // prevent it change to undefined to break controlled to uncontrolled rule            name={fieldName}
            label={label}
            disabled={disabled}
            required={required}
            event={event}
            icon="search"
            iconRight
            clearable
            onFocus={(e) => e.target.select()}
            onKeyDown={() => setVisited(true)}
            onChange={updateOptions}
            onBuild={
              onBuild
                ? (ul, items) => {
                    items.forEach((item, i) => {
                      const { label, value, children } = onBuild(item)
                      const li = document.createElement('LI')
                      CBUtils.attr(li, 'data-cb-value', label)
                      CBUtils.attr(li, 'data-cb-obj-label', label)
                      CBUtils.attr(li, 'data-cb-obj-value', value)
                      CBUtils.attr(li, 'data-cb-obj-full', JSON.stringify(item))
                      CBUtils.attr(li, 'role', 'presentation')

                      const a = document.createElement('a')
                      CBUtils.attr(a, 'href', '#')
                      CBUtils.attr(a, 'role', 'option')
                      a.classList.add('display-block')

                      a.innerHTML = children

                      li.appendChild(a)
                      ul.appendChild(li)
                    })
                  }
                : undefined
            }
            validationMsg={hasError ? errMsg : helperMsg}
            validation={valid}
            callBack={(e, label) => {
              // find parent li and set selected to saved full object attribute rather than "label"
              const parent = CBUtils.getClosest(e.target, 'li')
              const { cbObjFull } = parent.dataset || {}
              setValid('general')
              try {
                onSelection(JSON.parse(cbObjFull) || label)
              } catch {
                onSelection(label)
              }
            }}
          />
        </div>
        {hasError ? <Icon name="exclamation" className="cb-validation-icon" decorative /> : null}
      </div>
    </>
  )
}

Autocomplete.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSelection: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errMsg: PropTypes.string,
  helperMsg: PropTypes.string,
  onBuild: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  labelClassName: PropTypes.string,
}

export default Autocomplete
